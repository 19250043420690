import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ModalUtils } from '../../utils/modal-utils';
//import { BsModalService } from 'ngx-bootstrap/modal';

// Status de erro relacionado a FORBIDDEN.
const STATUS_ERROR_FORBIDDEN = 403;

@Injectable()
export class AuthorizationErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) //private modalService: BsModalService
  {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (resp) => {},
        (httpError: HttpErrorResponse) => {
          if (httpError.status === STATUS_ERROR_FORBIDDEN) {
            // fecha todos os modais
            // ModalUtils.closeAll(this.modalService);
            this.router.navigate(['403']);
          }
        }
      )
    );
  }
}
