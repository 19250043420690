import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'CurrencyCnpj'
})

export class CurrencyCnpj implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Formata o CNPJ no formato xx.xxx.xxx/xxxx-xx
    return value.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
  }
}