import { Component, ElementRef, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js/auto';  

interface ChartData {
  labels: string[];
  values: number[];
}

@Component({
  selector: 'app-grafico-operadora',
  templateUrl: './graficoOperadora.component.html',
  styleUrls: ['./graficoOperadora.component.css'],
})
export class GraficoOperadora {
  constructor() { }

  ngAfterViewInit(): void {
    this.createChart();
  }

  createChart(): void {
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    const myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['Operadora A', 'Operadora B', 'Operadora C', 'Operadora D'],
        datasets: [{
          label: '', 
          data: [65, 59, 80, 81],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        plugins: {
          legend: {
            display: false  // Removendo a legenda
          }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }
}