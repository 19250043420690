import { HttpParams } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, switchMap } from 'rxjs';
import { FormaPagamento } from 'src/app/modules/forma-pagamento/model/formaPagamento';
import { FormaPagamentoService } from 'src/app/modules/forma-pagamento/service/forma-pagamento.service';
import { FormValidatorService } from '../../services/form-validator.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StatusSituacao } from '../../enuns/status-situacao.enum';
@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog-forma-pagamento.html',
  styleUrls: ['./confirmation-dialog-forma-pagamento.scss'],
})
export class ConfirmationDialogComponentFormaPagamento implements OnInit  {

  form!: FormGroup<any>;
  formaPagamento!: Observable<FormaPagamento[]>;
  idFormaPagamento: number = -1; 

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponentFormaPagamento>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public formaPagamentoService: FormaPagamentoService,
  ) {
    this.getFormaPagamento();
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      formaPagamento:['',  [Validators.required]],
    });
  }

  private getFormaPagamento() {
    this.formaPagamento = this.formaPagamentoService.findAll(
      new HttpParams
    );
  }

  onConfirm(result: boolean): void {
    if (result) {
      this.formaPagamento.subscribe(formaPagamento => {
        this.idFormaPagamento = formaPagamento[0].id; 
        this.dialogRef.close(this.idFormaPagamento);
      });
    }  else {
      this.dialogRef.close(); 
    }
  }
  
}
