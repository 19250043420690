import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CategoriaPreferencia } from 'src/app/modules/categoria-preferencia/model/categoria-preferencia';
import { CategoriaPreferenciaService } from 'src/app/modules/categoria-preferencia/service/categoria-preferencia.service';
import { FormValidatorService } from '../../services/form-validator.service';
import { Operadora } from 'src/app/modules/operadora/model/operadora';
import { Fabricante } from 'src/app/modules/fabricante/model/fabricante';
import { SegmentacaoService } from 'src/app/modules/segmentacao/service/Segmentacao.service';
import { UserContextService } from 'src/app/infra/authz/services/user-context.service';
import { MessageService } from 'src/app/core/services/message.service';
import { FabricanteService } from 'src/app/modules/fabricante/service/fabricante.service';
import { OperadoraService } from 'src/app/modules/operadora/service/operadora.service';
import { Segmentacao } from 'src/app/modules/segmentacao/model/segmentacao';

@Component({
  selector: 'app-view-general-mercado',
  templateUrl: './view-general.component.mercado.html',
  styleUrls: ['./view-general.component.mercado.css'],
})
export class ViewGeneralCommandMercadoComponent implements OnInit {
  @Input() form!: FormGroup<any>;
  @Input() formValidator!: FormValidatorService;


  constructor(
    
  ) {

  }

  ngOnInit(): void {
  
  }




 
}

  

