<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel flat [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>business</mat-icon>
      <span class="space">Novo Mercado</span>
    </mat-expansion-panel-header>
    <table>
      <tr>
        <td>Título:</td>
        <td>{{ form.value.dados.titulo }}</td>
      </tr>

      <tr>
        <td>Segmento:</td>
        <td>{{ form.value.dados.idCategoriaPreferencia}}</td>
      </tr>

      <tr>
        <td>Observações:</td>
        <td>{{ form.value.dados.observacoes }}</td>

      </tr>

      <tr>
        <td>Instruções:</td>
        <td>{{ form.value.dados.instrucoes }}</td>
      </tr>
    </table>

  </mat-expansion-panel>


  <mat-expansion-panel flat [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>description</mat-icon>
      <span class="space">Artes</span>
    </mat-expansion-panel-header>

    <table>
      <tr>
        <td>Logo:</td>
        <td>{{ form.value.imagens }}</td>
      </tr>

      <tr>
        <td>Banner:</td>
        <td>{{ form.value.imagens}}</td>
      </tr>

      <tr>
        <td>Banner Novo:</td>
        <td>{{ form.value.imagens}}</td>
      </tr>

    </table>
  </mat-expansion-panel>



</mat-accordion>
