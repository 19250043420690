import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { HttpClient } from '@angular/common/http';
import { FormaPagamento } from '../model/formaPagamento';


@Injectable({
  providedIn: 'root',
})
export class FormaPagamentoService extends CrudService<FormaPagamento> {
  constructor(http: HttpClient) {
    super('formasPagamento', http);
  }
}
