import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { ChaveValor } from '../../model/chart/chave-valor';

@Component({
  selector: 'app-grafico-mes',
  templateUrl: './graficoMes.component.html',
  styleUrls: ['./graficoMes.component.css'],
})
export class GraficoMesComponent implements OnInit {
  @Input() dados!: ChaveValor[];
  @Input() cores!: string[];
  @Input() titulo!: string;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;

  private chart: Chart | undefined;

  ngOnInit() {
    Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dados'] && !changes['dados'].isFirstChange()) {
      this.updateChart();
    }
  }

  private createChart() {
    const monthNames = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const labels: string[] = monthNames;
    let values: number[] = Array(12).fill(0);

    this.dados.forEach(d => {
      const index = parseInt(d.label, 10) - 1;
      if (index >= 0 && index < 12) {
        values[index] = d.count;
      }
    });

    this.chart = new Chart(this.elemento.nativeElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: this.titulo,
            data: values,
            backgroundColor: this.cores,
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: '#333',
              font: {
                family: 'Poppins', 
                weight: 'bold',     
              },
            },
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 10,
              color: '#333',
              font: {
                family: 'Poppins', 
              },
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            labels: {
              font: {
                family: 'Poppins', 
              },
            },
          },
          datalabels: {
            color: 'white',
            font: {
              size: 12,
            },
          },
        },
      },
    });
  }

  private updateChart() {
    if (this.chart) {
      let values: number[] = Array(12).fill(0);

      this.dados.forEach(d => {
        const index = parseInt(d.label, 10) - 1;
        if (index >= 0 && index < 12) {
          values[index] = d.count;
        }
      });

      this.chart.data.datasets[0].data = values;
      this.chart.update();
    }
  }
}