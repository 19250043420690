<div style="text-align:center">
  <h1 class="titulo">Views / Desbloqueios</h1>
  <div class="options">
    <label for="month">Mês:</label>
    <select id="month" (select)="selectedMonth" (change)="updateChart()">
      <option *ngFor="let month of months; index as i" [value]="i">{{ month }}</option>
    </select>

    <label for="year">Ano:</label>
    <select id="year" (select)="selectedYear" (change)="updateChart()">
      <option *ngFor="let year of years" [value]="year">{{ year }}</option>
    </select>
  </div>
  <div>
    <canvas #myChart></canvas>
  </div>
</div>