import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit, OnDestroy {
  ngOnInit() {}

  ngOnDestroy(): void {}
}
