import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-verba-card',
  templateUrl: './verba-card.component.html',
  styleUrls: ['./verba-card.component.css'],
})
export class VerbaCardComponent implements OnInit {
  @Input() titulo!: string;
  @Input() valorAtual!: number;
  @Input() valorTotal!: number;

  constructor() {}

  ngOnInit() {}

  calcularPercentual(): number {
    return (this.valorAtual / this.valorTotal) * 100;
  }

  formatarValor(valor: number): string {
    if (valor >= 1000) {
      return (valor / 1000).toFixed(1) + 'k';
    } else {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(valor);
    }
  }
}
