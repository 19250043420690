import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import {
  Chart,
  CategoryScale,
  LinearScale,
  Tooltip,
  BarController,
  BarElement,
} from 'chart.js';
import { ChaveValorUsu } from '../../model/chart/chave-valor-usu';

@Component({
  selector: 'app-grafico-categoria-preferencia',
  templateUrl: './graficoCategoriaPreferencia.component.html',
  styleUrls: ['./graficoCategoriaPreferencia.component.css'],
})
export class GraficoCategoriaPreferenciaComponent implements OnInit {
  @Input() dados!: ChaveValorUsu[];
  @Input() corBarra!: string;
  @Input() titulo!: string;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;
  
  private chart: Chart | undefined;

  ngOnInit() {
    Chart.register(
      BarController,
      BarElement,
      LinearScale,
      CategoryScale,
      Tooltip
    );

    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dados'] && !changes['dados'].isFirstChange()) {
      this.updateChart();
    }
  }

  private createChart() {
    const values: number[] = this.dados.map((d) => d.value);
    const labels: string[] = this.dados.map((d) => d.label);

    this.chart = new Chart(this.elemento.nativeElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: this.titulo,
            data: values,
            backgroundColor: this.corBarra,
            borderColor: this.corBarra,
            borderWidth: 1,
          },
        ],
      },
      options: {
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            labels: {
              font: {
                family: 'Poppins', 
              },
            },
          },
          datalabels: {
            color: 'white',
            anchor: 'end',
            align: 'start',
            font: {
              family: 'Poppins',
            },
            formatter: function(value, context) {
              return value;
            }
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'y',
          intersect: false,
        },
        scales: {
          x: {
            beginAtZero: true,
          },
          y: {
            ticks: {
              autoSkip: false, 
              maxRotation: 0, 
              minRotation: 0,
              font: {
                family: 'Poppins', 
                size: 11, 
                weight: 'bold', 
              },
            },
          }
        }
      },
    });
  }

  private updateChart() {
    if (this.chart) {
      const values: number[] = this.dados.map((d) => d.value);
      this.chart.data.datasets[0].data = values;
      this.chart.update();
    }
  }
  
  
}