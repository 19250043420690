import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notificacao } from '../model/notificacao-response';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class notificacaoService {

  private notificationCountSubject = new BehaviorSubject<number>(0);
  public notificationCount$ = this.notificationCountSubject.asObservable();

  constructor(protected httpClient: HttpClient) {
    this.loadInitialNotification();
  }
  
  public notificacao(emNrId: number): Observable<Notificacao[]> {
    return this.httpClient.get<Notificacao[]>(
      `${environment.backendUrl}notificacoes/filter?keyempresa=${emNrId}&status=NAO_LIDO`
    );
  }

  public statusNotificacao(idNotificaco: number) {
    const url = environment.backendUrl + `notificacoes/${idNotificaco}`
    const body = {status: 'LIDO'};
    return this.httpClient.put<any>(url, body).pipe();
  }

  updateNotificationCount(count: number): void {
    this.notificationCountSubject.next(count);
  }

  update() : void{
    const novoValorNotificacao = this.getNotificationCount() + 1;
    this.updateNotificationCount(novoValorNotificacao);
  }

  getNotificationCount(): number {
    console.log(">>>" + this.notificationCountSubject.getValue())
    return this.notificationCountSubject.value;
  }

  private loadInitialNotification(): void {
    const savedCount = localStorage.getItem('notificationCount');
    if (savedCount) {
      this.notificationCountSubject.next(parseInt(savedCount, 10));
    }
  }

}
