import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserDados } from '../model/recuperar-senha';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class RecuperarSenhaService {

  constructor(private http: HttpClient) { }

    urlDados = environment.backendUrl + 'password/requestNewPassword';
    urlCod = environment.backendUrl + 'password/validateCodeForgetPassword';
    urlSenha= environment.backendUrl + 'password/changePassword';

  postDadosUser(user: UserDados) {
    return this.http.post<UserDados>(this.urlDados, user);
  }

  postCodigoUser(user: UserDados) {
    return this.http.post<boolean>(this.urlCod, user);
  }

  postSenhaUser(user: UserDados) {
    return this.http.post<UserDados>(this.urlSenha, user);
  }

}
