import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-crud-content',
  templateUrl: './crud-content.component.html',
  styleUrls: ['./crud-content.component.scss'],
})
export class CrudContentComponent {
  @Input() title: string | null = null;
  @Input() subTitle: string | null = null;
  @Output() onSave = new EventEmitter();
  @Output() onGoBack = new EventEmitter();
  @Input() width = '40%';
  @Input() layoutAlign = 'center top';
  @Input() buttonCancelVisible = true;
  @Input() buttonPersisteVisible = true;
  @Input() form!: FormGroup;

  public save() {
    this.onSave.emit();
  }
  public goBack() {
    this.onGoBack.emit();
  }
}
