import { LayoutComponent } from './../../model/layout/layoutComponent';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-dynamic',
  templateUrl: './form-dynamic.component.html',
  styleUrls: ['./form-dynamic.component.scss'],
})
export class FormDynamicComponent {
  @Input() form!: FormGroup;
  @Input() title: string = 'Filtro';
  @Output() filterEvent = new EventEmitter<any>();
  @Input() layoutComponent!: LayoutComponent;

  constructor(private fb: FormBuilder) {}

  public onFilter() {
    this.filterEvent.emit(this.form.value);
  }
}
