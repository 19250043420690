import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { Empresa } from '../model/empresa';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmpresaResumo } from '../model/empresa-resumo';

@Injectable({
  providedIn: 'root',
})
export class EmpresaResumoService extends CrudService<EmpresaResumo> {
  constructor(private httpClient: HttpClient) {
    super('empresas', httpClient);
  }

  public autoComplite(value: string): Observable<EmpresaResumo[]> {
    return this.httpClient.get<EmpresaResumo[]>(
      `${environment.backendUrl}empresas/autocomplite/fantasia/${value}`
    );
  }
}
