import { DependencyMatriz } from 'src/app/infra/model/dependency-matriz';
import { Identifiable } from 'src/app/infra/model/identifiable';

import { AutenticacaoEmpresa } from './autenticacao-empresa';
import { ContatoEmpresa } from './contato-empresa';
import { DadosEmpresa } from './dados-empresa';
import { Endereco } from './endereco';
import { IdentifiableMatriz } from 'src/app/core/model/identifiableMatriz';
import { StatusSituacao } from 'src/app/shared/enuns/status-situacao.enum';

export class Empresa implements Identifiable {
  id!: number;
  keyempresa!: number;
  dados!: DadosEmpresa;
  contato!: ContatoEmpresa;
  endereco!: Endereco;
  autenticacao!: AutenticacaoEmpresa;
  statusPeformace!: StatusSituacao ;
}
