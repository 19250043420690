import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { Fabricante } from '../model/fabricante';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FabricanteService extends CrudService<Fabricante> {
  constructor(public htppClient: HttpClient) {
    super('fabricantes', htppClient);
  }
}
