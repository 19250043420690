import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { Segmentacao } from '../model/segmentacao';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SegmentacaoService extends CrudService<Segmentacao> {
  constructor(public httpClient: HttpClient) {
    super('demografias', httpClient);
  }
}
