import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

@Component({
  selector: 'app-toggle-dash',
  templateUrl: './toggle-dash.component.html',
  styleUrls: ['./toggle-dash.component.css'],
})
export class ToggleDashComponent {
  @Input() titulo!: string;
  @Input() filtros: string[] = [];

  @Output() toggleChange = new EventEmitter();

  change(selected: any) {
    console.log('toggle');
    console.log(selected.value);
    this.toggleChange.emit(selected.value);
  }
}
