<div [formGroup]="form">
  <mat-form-field fxFlex="{{ width }}" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      placeholder="{{ placeholder }}"
      formControlName="{{ formControlName }}"
      mask="00.000.000/0000-00"
      maxLength="18"
    />

    <mat-error *ngIf="form.get(fieldFormError)?.invalid">
      {{ formValidator.getErrorMessage(form,fieldFormError) }}
      </mat-error
    >
  </mat-form-field>
</div>