import { CommonModule } from '@angular/common';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { from } from 'rxjs';
import { Component, Input, forwardRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-text-field-number',
  templateUrl: './text-field-number.component.html',
  styleUrl: './text-field-number.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldNumberComponent),
      multi: true
    }
  ]
})

export class TextFieldNumberComponent implements ControlValueAccessor {
  @Input() width: string = '100%';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() formControlName: string = '';
  @Input() fieldFormError: string = '';
  @Input() formEnd!: FormGroup;
  @Input() formValidator!: FormValidatorService;

  constructor(private formBuild: FormBuilder) {}

  onInput(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');


    if(this.fieldFormError == 'experienciaMinima' || this.fieldFormError == 'experienciaMaxima'){
      if(event.target.value.length > 9){
        event.target.value = event.target.value.slice(0, 9);
      }

    }
 
  }

  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

}
