import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHandler, HttpInterceptor, HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserContextService } from '../services/user-context.service';
import { UserContext } from '../model/user-context';

const hUserContext: string = 'X-UserContext';

/**
 * Intercepta o response header 'X-UserContext' para receber atualização de UserContext.
 * `UserContext` carrega um JWT no atributo `UserContext.token`.
 */
@Injectable()
export class UserContextInterceptor implements HttpInterceptor {
  constructor(private userContextService: UserContextService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((next) => {
        if (next.type == HttpEventType.Response) {
          const resp: HttpResponse<any> = next;
          const ucString = resp.headers.get(hUserContext);
          if (ucString) {
            const uc: UserContext = UserContext.parseJson(ucString);
            this.userContextService.set(uc);
          }
        }

        return next;
      })
    );
  }
}
