<div [formGroup]="formContato">
  <mat-form-field fxFlex="{{width}}" appearance="outline">
      <mat-label>{{ label }}</mat-label>
      <input
        matInput
        placeholder="{{placeholder}}"
        formControlName="{{formControlName}}"
        mask="(00) 0 0000-0000"
      />
      <mat-icon matSuffix>phone</mat-icon> 
      <mat-error *ngIf="formContato.get(fieldformError)?.invalid">
        {{
          formValidator.getErrorMessage(formContato,fieldformError)
        }}</mat-error
      >
  </mat-form-field>
</div>
