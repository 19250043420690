<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel flat [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>business</mat-icon>
      <span class="space label-group">Modelo</span>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutGap="10px">
      <app-row-layout *ngIf="form.value.modelo.tipoCampanha.id">
        <span fxFlex="17%" class="label">Tipo Campanha:</span>
        <span fxFlex="83%"
          >{{ onChangeTipoCampanha(form.value.modelo.tipoCampanha.id) }}</span
        >
      </app-row-layout>

      <app-row-layout>
        <span fxFlex="17%" class="label">Data de Início:</span>
        <span fxFlex="83%"
          >{{ form.value.modelo.dataInicio | date: 'dd/MM/yyyy' }}</span
        >
      </app-row-layout>

      <app-row-layout>
        <span class="label">Data de Término:</span>
        <span class="value"
          >{{ form.value.modelo.dataTermino | date: 'dd/MM/yyyy' }}</span
        >
      </app-row-layout>

      <app-row-layout
        fxLayoutAlign="space-between center"
        *ngIf="form.value.modelo.tipoCampanha.id == 8 || form.value.modelo.tipoCampanha.id == 7"
      >
        <span fxFlex="17%" class="label">Valor Verba:</span>
        <span fxFlex="83%"
          >{{ form.value.modelo.valorVerba | currency:'BRL':'symbol':'1.2-2'
          }}</span
        >
      </app-row-layout>

      <app-row-layout
        fxLayoutAlign="space-between center"
        *ngIf="form.value.modelo.tipoCampanha.id == 6"
      >
        <span fxFlex="17%" class="label">Valor Verba:</span>
        <span fxFlex="83%"
          >{{ form.value.modelo.valorVerba | currency:'BRL':'symbol':'1.2-2'
          }}</span
        >
      </app-row-layout>

      <app-row-layout *ngIf="form.value.modelo.tipoCampanha.id == 6">
        <span fxFlex="17%" class="label">Valor Verba Dia:</span>
        <span fxFlex="83%"
          >{{ form.value.modelo.valorVerbaDia | currency:'BRL':'symbol':'1.2-2'
          }}</span
        >
      </app-row-layout>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel flat [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>description</mat-icon>
      <span class="space label-group">Dados</span>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutGap="10px">
      <app-row-layout>
        <span fxFlex="17%" class="label">Título Campanha:</span>
        <span fxFlex="83%">{{ form.value.dados.titulo }}</span>
      </app-row-layout>

      <app-row-layout>
        <span fxFlex="17%" class="label">Observações:</span>
        <span fxFlex="83%">{{ form.value.dados.observacoes }}</span>
      </app-row-layout>

      <app-row-layout>
        <span fxFlex="17%" class="label">Link Anúncio:</span>
        <span fxFlex="83%">{{ form.value.dados.urlAnuncio }}</span>
      </app-row-layout>

      <app-row-layout>
        <span fxFlex="17%" class="label">Endereço da PlayStory:</span>
        <span fxFlex="83%">{{ form.value.dados.playStoreUrl }}</span>
      </app-row-layout>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel flat [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>location_city</mat-icon>
      <span class="space label-group">Regiões</span>
    </mat-expansion-panel-header>

    <div fxLayout="column" fxLayoutGap="10px">
      <app-row-layout *ngIf="form.value.regiao.opcao">
        <span fxFlex="17%" class="label">Região:</span>
        <span fxFlex="83%">{{ getCase(form.value.regiao.opcao) }}</span>
      </app-row-layout>

      <app-row-layout *ngIf="form.value.regiao.opcao == 'REGIOES'">
        <span fxFlex="17%" class="label">Estados:</span>
        <span fxFlex="83%"
          >{{ onChangeEstado(form.value.regiao.formEstados) }}</span
        >
      </app-row-layout>

      <app-row-layout *ngIf="form.value.regiao.opcao == 'GEO_LOCALIZACAO'">
        <span fxFlex="17%" class="label">Cep:</span>
        <span fxFlex="87%">{{ form.value.regiao.localizacao }}</span>
      </app-row-layout>

      <app-row-layout *ngIf="form.value.regiao.opcao == 'GEO_LOCALIZACAO'">
        <span fxFlex="17%" class="label">Raio:</span>
        <span fxFlex="83%">{{ form.value.regiao.raio }}</span>
      </app-row-layout>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel flat [expanded]="true" flex>
    <mat-expansion-panel-header>
      <mat-icon>category</mat-icon>
      <span class="space label-group">Segmentação</span>
    </mat-expansion-panel-header>
    <app-row-layout>
      <div class="label" fxFlex="17%">Nome da Segmentação:</div>
      <div fxFlex="83%">{{ form.value.segmentacao.nome }}</div>
    </app-row-layout>
    <app-row-layout *ngIf="form.value.segmentacao.genero">
      <div class="label" fxFlex="20%">Gênero:</div>
      <div fxFlex="80%">
        <div class="value">
          <span *ngIf="form.value.segmentacao.genero.masculino">Masculino</span>
          <span class="space" *ngIf="form.value.segmentacao.genero.feminino"
            >Feminino</span
          >
          <span class="space" *ngIf="form.value.segmentacao.genero.outros"
            >Outros</span
          >
        </div>
      </div>
    </app-row-layout>
    <app-row-layout>
      <div class="label" fxFlex="17%">Idade:</div>
      <div class="value" fxFlex="83%">
        {{ form.value.segmentacao.idade.minima }} - {{
        form.value.segmentacao.idade.maxima }}
      </div>
    </app-row-layout>
    <app-row-layout *ngIf="form.value.segmentacao.conexao">
      <div class="label" fxFlex="17%">Conexão:</div>
      <div class="value" fxFlex="83%">
        <span *ngIf="form.value.segmentacao.conexao.movel">Móvel</span>
        <span class="space" *ngIf="form.value.segmentacao.conexao.wifi"
          >Wi-Fi</span
        >
      </div>
    </app-row-layout>
    <p></p>

    <app-row-layout>
      <div class="label" fxFlex="100%">Preferências:</div>
    </app-row-layout>
    <mat-divider></mat-divider>
    <p></p>
    <app-row-layout>
      @for (item of
      onChangeSegmentacaoCategoriasPreferencias(form.value.segmentacao.categoriasPreferencias);
      track $index) {

      <div fxFlex="210px"><mat-chip>{{item}}</mat-chip></div>

      }
    </app-row-layout>
    <p></p>
    <app-row-layout>
      <div class="label" fxFlex="100%">Fabricantes:</div>
    </app-row-layout>
    <mat-divider></mat-divider>
    <p></p>
    <app-row-layout>
      @for (item of
      onChangeSegmentacaoFabricante(form.value.segmentacao.fabricantes); track
      $index) {

      <div fxFlex="210px"><mat-chip>{{item}}</mat-chip></div>

      }
    </app-row-layout>
    <p></p>
    <app-row-layout>
      <div class="label" fxFlex="100%">Operadoras:</div>
    </app-row-layout>
    <mat-divider></mat-divider>
    <p></p>
    <app-row-layout>
      @for (item of
      onChangeSegmentacaoOperadora(form.value.segmentacao.operadoras); track
      $index) {

      <div fxFlex="150px"><mat-chip>{{item}}</mat-chip></div>

      }
    </app-row-layout>
  </mat-expansion-panel>

  <mat-expansion-panel flat [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>image</mat-icon>
      <span class="space label-group">Publicidade</span>
    </mat-expansion-panel-header>
    <div class="image-container">
      <div>
        <p class="label">Etiqueta:</p>
        <p>(350 x 350 / Máximo 300kb)</p>
        <img
          class="image"
          [src]="onChangeImage(form.value.publicidade.dataEtiqueta,'ET')"
          alt="Etiqueta"
        />
      </div>

      <div
        *ngIf="form.value.publicidade.dataBannerPrime ||form.value.publicidade.urlBannerPrime "
      >
        <p class="label">Banner Primer:</p>
        <p>(735 x 1080 / Máximo 600kb)</p>
        <img
          class="image"
          [src]="onChangeImage(form.value.publicidade.dataBannerPrime, 'BP')"
          alt="Banner Primer"
        />
      </div>

      <div *ngIf="form.value.publicidade.dataGif">
        <p class="label">Gif:</p>
        <p>(750 x 1338 / Máximo 2MB)</p>
        <img
          class="image"
          [src]="onChangeGif(form.value.publicidade.dataGif ||form.value.publicidade.urlGif )"
          alt="Gif"
        />
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
