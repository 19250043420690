export const Constantes = {
  FORMAT_DATE_DDMMYYYY: 'DDMMYYYY',
  INSERT_SUCESS: 'Registro gravado com sucesso.',
  DELETE_SUCESS: 'Registro excluído com sucesso.',
  UPDATE_SUCESS: 'Registro atualizado com sucesso.',
  PERFIL_ADM: 'ADMINISTRADOR',
  PERFIL_ANUNCIANTE: 'ANUNCIANTE',
  FIELD_KEY_EMPRESA: 'keyempresa',
  IMG_ETIQUETA: '../../../assets/img/etiqueta.png',
  IMG_PRIME: '../../../assets/img/prime.png',
  IMG_BANNER: '../../../assets/img/banner.png',
  IMG_GIF: '../../../assets/img/gif.png',
  FILTRO_MENSAL: 1,
  FILTRO_ANUAL: 2,
};
