<block-ui>
  <app-page-content>
    <mat-card fxFlex style="width: 100%">
      <mat-table
        [dataSource]="dataSource"
        class="mat-elevation-z8 table-container"
      >
        <ng-container
          *ngFor="let column of columns"
          [matColumnDef]="column.field"
        >
          <mat-header-cell *matHeaderCellDef>
            {{ column.title  }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <ng-container *ngIf="column.field === 'verba'; else template">
              {{ element[column.field] | currency:'BRL':'symbol':'1.2-2' }}
            </ng-container>

            <ng-template #template>
              {{ getColumnData(element, column.field) }}
            </ng-template>
          
          </mat-cell>

          <mat-cell *matCellDef="let element">
            @if (! column.isBoolean) {
            {{ getColumnData(element, column.field) }}
            }@else {
            <mat-checkbox
              [checked]="isChecked(getColumnData(element, column.field))"
              [disabled]="true"
            ></mat-checkbox>
            }
          </mat-cell>
        </ng-container>
        <!-- Actions Column -->

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="action-column">
            <button
              mat-mini-fab
              color="primary"
              aria-label="Adicionar registro"
              (click)="onNew()"
              class="action-column-header"
            >
              <mat-icon>note_add</mat-icon>
            </button>
          </mat-header-cell>

          <mat-cell *matCellDef="let register" class="action-column">
            <button
              mat-button
              color="primary"
              aria-label="Editar Registro"
              (click)="onEdit(register)"
            >
              <span>Editar</span>
            </button>

            <button
              mat-icon-button
              color="warn"
              aria-label="Remover Registro"
              (click)="onDelete(register)"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        [length]="page.totalElements"
        [pageIndex]="page.number"
        [pageSize]="page.size"
        aria-label="Select page of periodic elements"
        (page)="onChangePage($event)"
      >
      </mat-paginator>
    </mat-card>
  </app-page-content>
</block-ui>