import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { Operadora } from '../model/operadora';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OperadoraService extends CrudService<Operadora> {
  constructor(httpClient: HttpClient) {
    super('operadoras', httpClient);
  }
}
