<block-ui>
  <div
    fxFlexFill
    fxLayout="row"
    fxLayoutAlign="{{ layoutAlign }}"
    class="div-padding"
  >
    <div fxFlex="{{ width }}" fxLayout="column" class="marge-card">
      <mat-card fxLayout="column">
        <mat-card-header color="primary">
          <mat-card-title>{{ title }}</mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content style="padding-top: 20px">
          <ng-content></ng-content>
        </mat-card-content>

        <mat-card-actions
          fxLayoutAlign="end"
          fxLayoutGap="10px"
          style="padding-right: 20px"
        >
          <button
            mat-raised-button
            color="primary"
            (click)="save()"
            [disabled]="!form.valid"
            [formGroup]="form"
            class="button-confirm"
          >
            Confirmar
          </button>

          <button mat-raised-button (click)="goBack()" class="button-cancel">Cancelar</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</block-ui>