import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { notificacaoService } from './service/notificacao-service';
import { Notificacao } from './model/notificacao-response';
import { UserContextService } from 'src/app/infra/authz/services/user-context.service';


@Component({
  selector: 'app-notificacao',
  templateUrl: './notificacao.component.html',
  styleUrls: ['./notificacao.component.scss'],
})
export class NotificacaoComponent implements OnInit {
  
  @Output() closeNotificacao: EventEmitter<void> = new EventEmitter<void>();

  empNrId: number = 0;
  cartItems: Notificacao[] = [];


  ngOnInit(): void {
    this.loadNotificacoes(this.empNrId); 
  }


  constructor(
    private notificacaoService: notificacaoService,
    public useContext: UserContextService
  ) {
    this.empNrId = this.useContext.getIdUserStorageEmpresa();
  }

  loadNotificacoes(value: number): void {
    this.notificacaoService.notificacao(value).subscribe((response: any) => {
      this.cartItems = response.content || []; 
      const count = this.cartItems.length;
      this.notificacaoService.updateNotificationCount(count);
    });
  }

  statusItem(item : any) {
    this.notificacaoService.statusNotificacao(item.id).subscribe(
      (response) => {
        this.cartItems = this.cartItems.filter(cartItem => cartItem !== item);
        const count = this.cartItems.length;
        this.notificacaoService.updateNotificationCount(count);
        if (count === 0) {
          this.closeNotificacao.emit(); 
        }
      },
      (error) => {
        console.error('Erro ao atualizar a notificação:', error);
      }
    );
  }
  
}
