//import { version } from '../../package.json'

export const environment = {
  production: false,
  backendUrl: 'https://api.bubling.com.br/bubling-api/',
  //backendUrl: 'http://localhost:8080/bubling-api/',
  defaultLanguage: 'pt-BR',
  defaultCurrencyCode: 'BRL',
  googleApiKey: 'AIzaSyA9AoJ3Bnrcljgv1ofipYGvQ-ptbSpzWGw',
  //  version,
};
