import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  ViewChild,
  inject,
  OnInit,
  Input,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { EmpresaService } from 'src/app/modules/empresa/service/empresa.service';
import { Empresa } from 'src/app/modules/empresa/model/empresa';
import { UserContextService } from 'src/app/infra/authz/services/user-context.service';
import { EmpresaResumo } from 'src/app/modules/empresa/model/empresa-resumo';
import { EmpresaResumoService } from 'src/app/modules/empresa/service/empresa-resumo.service';

@Component({
  selector: 'app-search-empresa',
  templateUrl: './search-empresa.component.html',
  styleUrls: ['./search-empresa.component.css'],
})
export class SearchEmpresaComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formEmpresa = new FormControl();
  public filteredEmpresas!: Observable<EmpresaResumo[]>;

  empresas: EmpresaResumo[] = [];
  empresasSelected: EmpresaResumo[] = [];
  announcer = inject(LiveAnnouncer);

  @ViewChild('empresaInput') empresaInput!: ElementRef<HTMLInputElement>;
  constructor(
    public empresaService: EmpresaResumoService,
    public userContext: UserContextService
  ) {}

  @Input() isExpanded!: boolean;
  @Input() isShowing!: boolean;

  add(event: MatChipInputEvent): void {
    console.log('add ');
    console.log('add ');

    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      //this.fruits.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.formEmpresa.setValue(null);
  }

  ngOnInit() {
    this.search('abcdef');
    this.filteredEmpresas = this.formEmpresa.valueChanges.pipe(
      startWith(null),
      map((fantasia: string | null) =>
        fantasia ? this._filter(fantasia) : this.empresas
      )
    );
  }

  search(value: any): void {
    this.empresaService.autoComplite(value + '').subscribe(
      (data) => {
        this.updateFilter(data);
      },
      (error) => console.log(error)
    );
  }

  updateFilter(empresa: EmpresaResumo[]) {
    console.log('update filter  ' + JSON.stringify(empresa));
    console.log('update filter ' + empresa);
    this.empresas = empresa;
  }

  remove(empresa: EmpresaResumo): void {
    const index = this.empresasSelected.indexOf(empresa);
    console.log('removendo ' + index);
    if (index >= 0) {
      this.empresasSelected.splice(index, 1);
      this.announcer.announce(`Removed ${empresa}`);
      this.userContext.removeKeyEmpresa();
    }
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    this.empresasSelected = [];
    console.log('selected ' + JSON.stringify(event.option.value));
    this.empresasSelected.push(event.option.value);
    this.empresaInput.nativeElement.value = '';
    this.userContext.addkeyEmpresa(event.option.value);
  }

  

  private _filter(value: any): EmpresaResumo[] {
    const filterValue = (value && typeof value === 'object' && value.fantasia) ?
      value.fantasia.toLowerCase() : value.toLowerCase();
      console.log("^^^^"+ value.fantasia)
  
    if (!filterValue) {
      return [];
    }
    if (!(filterValue instanceof Function)) {
      this.search(filterValue);
    }
    return this.empresas.filter((empresa) =>
      empresa && empresa.fantasia &&
      empresa.fantasia.toLowerCase().includes(filterValue)
    );
  }
  


  public isPerfilAdm(): boolean {
    return this.userContext.isProfileAdmin();
  }
}