<mat-nav-list dense *ngIf="menuList | async as menus">
  <mat-accordion displayMode="default" [hideToggle]="false" displayMode="flat">
    <mat-expansion-panel
      [class.mat-elevation-z0]="true"
      dense
      *ngFor="let menu of menus"
      [hideToggle]="!isExpanded && !isShowing"
    >
      <mat-expansion-panel-header>
        <div class="div-position">
          <mat-icon color="primary" fxFlex>{{ menu.icon }}</mat-icon>
          <span *ngIf="isExpanded || isShowing" fxFlex>{{ menu.label }}</span>
        </div>
      </mat-expansion-panel-header>
      @for (item of menu.subMenus; track $index) {
      <p>
        <a
          [routerLink]="[item.action]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          *ngIf="isExpanded || isShowing"
          class="aling item-label"
        >
          {{ item.label }}
        </a>
      </p>
      <mat-divider></mat-divider>
      }
    </mat-expansion-panel>
  </mat-accordion>
</mat-nav-list>
