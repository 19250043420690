import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  durationInSeconds: number = 3;
  constructor(private snackBar: MatSnackBar) {}

  private show(
    message: string,
    action: any,
    durationInSeconds: number,
    cssClass: string
  ) {
    this.snackBar.open(message, action, {
      duration: durationInSeconds * 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [cssClass],
    });
  }

  info(message: string) {
    this.show(message, 'x', 3, 'info-snackbar');
  }

  error(message: string) {
    this.show(message, 'x', 6, 'error-snackbar');
  }

  warning(message: string) {
    this.show(message, 'x', 3, 'warning-snackbar');
  }

  success(message: string) {
    this.show(message, 'x', 3, 'sucess-snackbar');
  }
}
