import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
  registerLocaleData,
} from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import {
  DEFAULT_CURRENCY_CODE,
  Injector,
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { BlockUIModule } from 'ng-block-ui';
import { environment } from 'src/environments/environment';

import { httpInterceptorProviders } from '../infra/http-interceptors';
import { AppMaterialModule } from '../shared/app-material/app-material.module';
import { SpinnerComponent } from '../shared/components/spinner/spinner.component';
import { DefaultLayoutComponent } from './components/default-layout/default-layout.component';
import { MenuComponent } from './components/menu/menu.component';
import { SearchEmpresaComponent } from './components/search-empresa/search-empresa.component';
import { throwIfAlreadyLoaded } from './guards/module-import-guard';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FlexLayoutModule } from '@angular/flex-layout';

// containers
const APP_CONTAINERS = [DefaultLayoutComponent];

/* Armazena o Injector do angular, para permitir recuperar as instancias dos serviços, componentes etc
   sem a necessidade de um construtor com o Injector. */
export let appInjector: Injector;

/**
 * Definindo locale padrão para o ngx-bootstrap.
 */

@NgModule({ declarations: [MenuComponent, SearchEmpresaComponent],
    exports: [BlockUIModule, MenuComponent, SearchEmpresaComponent], imports: [AppMaterialModule,
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        BlockUIModule.forRoot({
            message: 'Carregando...',
            delayStart: 500,
            delayStop: 500,
            template: SpinnerComponent,
        }),
        NgxMaskDirective,
        NgxMaskPipe,
        FormsModule,
        FlexLayoutModule], providers: [
        httpInterceptorProviders,
        { provide: LOCALE_ID, useValue: environment.defaultLanguage },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: environment.defaultCurrencyCode,
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy,
        },
        provideNgxMask(),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    injector: Injector
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');

    appInjector = injector;

    // definir Locale padrão app
  }
}

registerLocaleData(localePt, 'pt');
