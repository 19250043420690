<div [formGroup]="formEnd">
  <mat-form-field fxFlex="{{ width }}" appearance="outline">
      <mat-label>{{ label }}</mat-label>
      <input
        matInput
        placeholder="{{ placeholder }}"
        formControlName="{{ formControlName }}"
        mask="00000-000"
      />

      <mat-error *ngIf="formEnd.get(fieldFormError)?.invalid">
        {{
          formValidator.getErrorMessage(formEnd,fieldFormError)
        }}</mat-error
      >

  </mat-form-field>
</div>