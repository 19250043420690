import { HttpClient } from '@angular/common/http';
import { Component} from '@angular/core';
import { environment } from 'src/environments/environment';
import { Constantes } from '../../constantes/constantes';

@Component({
  selector: 'app-grafico-desbloqueio-usu',
  templateUrl: './graficoDesbloqueiosUsu.component.html',
  styleUrls: ['./graficoDesbloqueiosUsu.component.css'],
})
export class GraficoDesbloqueiosUsucomponent {
  selectedOption = Constantes.FILTRO_ANUAL; 
  selectedMonth: number = new Date().getMonth() + 1; 
  selectedYear: number = new Date().getFullYear(); 
  showMonth: boolean = true; 
  FILTRO_MENSAL = 1;
  FILTRO_ANUAL = 2;
  data: any = {
    idade: [],
    estado: [],
    hora: [],
    genero: [],
    dia: [],
    diaSemana: [],
    mes: []
  }; 

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchData(this.selectedMonth, this.selectedYear, this.selectedOption);
    console.log("Mês inicial: " + this.selectedMonth);
  }

  fetchData(month: number, year: number, option: Object): void {
    const url = `${environment.backendUrl}desbloqueios/usuario/op/${option}/mes/${month}/ano/${year}`;
    this.http.get<any>(url).subscribe(data => {
      if (data) {
        this.data = {
          idade: data.idade ? data.idade.map((item: any) => ({ label: item.label, count: item.quant })) : [{ label: 'Idade', count: 0 }],
          estado: data.estado ? data.estado.map((item: any) => ({ label: item.label, count: item.quant })) : [{ label: 'Estado', count: 0 }],
          hora: data.hora ? data.hora.map((item: any) => ({ label: item.label, count: item.quant })) : [{ label: 'Hora', count: 0 }],
          genero: data.genero ? data.genero.map((item: any) => ({ label: item.label, count: item.quant })) : [{ label: 'Gênero', count: 0 }],
          dia: data.dia ? data.dia.map((item: any) => ({ label: item.label, count: item.quant })) : [{ label: 'Dia', count: 0 }],
          diaSemana: data.diaSemana ? data.diaSemana.map((item: any) => ({ label: item.label, count: item.quant })) : [{ label: 'Dia da Semana', count: 0 }],
          mes: data.mes ? data.mes.map((item: any) => ({ label: item.label, count: item.quant })) : [{ label: 'Mês', count: 0 }],
  
        }; 
      } else {
        console.error("Erro ");
      }
    }, error => {
      console.error("erro", error);
    });
  }

  onChangeMonth(event: Event): void {
    this.selectedMonth = Number((event.target as HTMLSelectElement).value);
    this.fetchData(this.selectedMonth, this.selectedYear, this.selectedOption);
  }

  onChangeYear(event: Event): void {
    this.selectedYear = Number((event.target as HTMLSelectElement).value);
    this.fetchData(this.selectedMonth, this.selectedYear, this.selectedOption);
  }

  onChangeOption(event: any) {
    if (event.target.value === 'month') {
      this.showMonth = true;
      this.selectedOption = Constantes.FILTRO_MENSAL; 
      this.fetchData(this.selectedMonth, this.selectedYear, this.selectedOption);
    } else {
      this.showMonth = false;
      this.selectedMonth = 0; 
      this.selectedOption = Constantes.FILTRO_ANUAL; 
      this.fetchData(this.selectedMonth, this.selectedYear, this.selectedOption);

    }
  }

}