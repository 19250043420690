import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ChaveValorUsu } from '../../model/chart/chave-valor-usu';

declare var google: any;

interface EstadoMap {
  [key: string]: string;
}

@Component({
  selector: 'app-graficoEstadosUsu',
  templateUrl: './graficoEstadosUsu.component.html',
  styleUrls: ['./graficoEstadosUsu.component.css'],
})
export class GraficoEstadosUsuComponent implements AfterViewInit {
  @Input() values!: ChaveValorUsu[];
  @Input() titulo!: string;
  @ViewChild('geochart') geochartElement!: ElementRef;

  ngAfterViewInit() {
    google.charts.load('current', {
      packages: ['geochart'],
    });
    google.charts.setOnLoadCallback(this.drawRegionsMap.bind(this));
  }

  drawRegionsMap() {
    const estadoMap: EstadoMap  = {
      'Acre': 'BR-AC',
      'Alagoas': 'BR-AL',
      'Amazonas': 'BR-AM',
      'Amapá': 'BR-AP',
      'Bahia': 'BR-BA',
      'Ceará': 'BR-CE',
      'Distrito Federal': 'BR-DF',
      'Espírito Santo': 'BR-ES',
      'Goiás': 'BR-GO',
      'Maranhão': 'BR-MA',
      'Minas Gerais': 'BR-MG',
      'Mato Grosso do Sul': 'BR-MS',
      'Mato Grosso': 'BR-MT',
      'Pará': 'BR-PA',
      'Paraíba': 'BR-PB',
      'Pernambuco': 'BR-PE',
      'Piauí': 'BR-PI',
      'Paraná': 'BR-PR',
      'Rio de Janeiro': 'BR-RJ',
      'Rio Grande do Norte': 'BR-RN',
      'Rondônia': 'BR-RO',
      'Roraima': 'BR-RR',
      'Rio Grande do Sul': 'BR-RS',
      'Santa Catarina': 'BR-SC',
      'Sergipe': 'BR-SE',
      'São Paulo': 'BR-SP',
      'Tocantins': 'BR-TO'
    };

    const data = google.visualization.arrayToDataTable([
      ['State', 'Usuários'],
      ...this.values.map(v => [estadoMap[v.label] || v.label, v.value])
    ]);

    const options = {
      region: 'BR',
      resolution: 'provinces',
      colorAxis: { colors: ['#23CF2A'] },
      backgroundColor: '#ffff',
      datalessRegionColor: '#00',
      defaultColor: '#f5f5f5',
    };

    const chart = new google.visualization.GeoChart(
      this.geochartElement.nativeElement
    );
    chart.draw(data, options);
  }
}