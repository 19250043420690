<div [formGroup]="form">
  <mat-form-field fxFlex="{{ width }}" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      [matDatepicker]="inicio"
      placeholder="{{ placeholder }}"
      formControlName="{{ formControlName }}"
      (input)="onInput($event)"
    />

    <mat-datepicker-toggle style="color: #523f91; " matIconSuffix [for]="inicio"></mat-datepicker-toggle>
    <mat-datepicker #inicio></mat-datepicker>
    <mat-error *ngIf="form.get(fieldformError)?.invalid">
      {{ formValidator.getErrorMessage(form, fieldformError) }}</mat-error
    >
  </mat-form-field>

  
</div>
