import { Component, ElementRef, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration } from 'chart.js/auto';  

interface ChartData {
  labels: string[];
  values: number[];
}

@Component({
  selector: 'app-grafico-view',
  templateUrl: './graficoView.component.html',
  styleUrls: ['./graficoView.component.css'],
})
export class GraficoView {
  @ViewChild('myChart') myChartRef!: ElementRef<HTMLCanvasElement>;
  myChart!: Chart;

  selectedMonth!: number;
  selectedYear!: number;
  months: string[] = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];
  years: number[] = [2021, 2022, 2023, 2024];

  constructor() { }

  ngAfterViewInit(): void {
    this.selectedMonth = new Date().getMonth();
    this.selectedYear = new Date().getFullYear();
    this.createChart();
  }

  createChart(): void {
    const ctx = this.myChartRef.nativeElement.getContext('2d');
    if (ctx) {  // Verifique se o contexto não é nulo
      this.myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.getDaysInMonth(this.selectedMonth, this.selectedYear),
          datasets: [{
            label: 'Desbloqueios',
            data: this.generateRandomData(),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            y: {
              beginAtZero: true
            }
          },
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
    }
  }

  updateChart(): void {
    this.myChart.data.labels = this.getDaysInMonth(this.selectedMonth, this.selectedYear);
    this.myChart.data.datasets[0].data = this.generateRandomData();
    this.myChart.update();
  }

  getDaysInMonth(month: number, year: number): string[] {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(new Intl.DateTimeFormat('pt-BR', { day: '2-digit' }).format(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  generateRandomData(): number[] {
    return Array.from({ length: this.getDaysInMonth(this.selectedMonth, this.selectedYear).length }, () => Math.floor(Math.random() * 100));
  }
}