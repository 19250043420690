import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChaveValor } from '../../model/chart/chave-valor';

declare var google: any;

@Component({
  selector: 'app-graficoEstadosUsuDesblo',
  templateUrl: './graficoEstadosUsuDesblo.component.html',
  styleUrls: ['./graficoEstadosUsuDesblo.component.css'],
})
export class GraficoEstadosUsuDesbloComponent implements AfterViewInit {
  @Input() values!: ChaveValor[];
  @Input() titulo!: string;

  @ViewChild('geochart') geochartElement!: ElementRef;

  private chart: any;

  ngAfterViewInit() {
    google.charts.load('current', { packages: ['geochart'] });
    google.charts.setOnLoadCallback(() => {
      this.drawRegionsMap();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['values'] && !changes['values'].isFirstChange()) {
      this.updateChart();
    }
  }

  drawRegionsMap() {
    const data = google.visualization.arrayToDataTable([
      ['State', 'Interações'],
      ...this.values.map(v => [`BR-${v.label}`, v.count]) 
    ]);

    const options = {
      region: 'BR',
      resolution: 'provinces',
      colorAxis: { colors: ['#23CF2A'] },
      backgroundColor: '#ffff',
      datalessRegionColor: '#00',
      defaultColor: '#f5f5f5',
    };

    this.chart = new google.visualization.GeoChart(this.geochartElement.nativeElement);
    this.chart.draw(data, options);
  }

  updateChart() {
    if (this.chart) {
      this.drawRegionsMap();
    }
  }
  
}
