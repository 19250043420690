<div mat-dialog-content [formGroup]="form">
  <p>{{ data.message }}</p>
  <app-row-layout>
    <mat-form-field
      fxFlex="100%"
      appearance="outline"
    >
      <mat-label>Opção Pagamento</mat-label>
      <mat-select formControlName="formaPagamento">
        @for (item of formaPagamento |async; track $index) {
        <mat-option [value]="item.id">{{ item.nome }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </app-row-layout>
</div>
<div mat-dialog-actions fxLayoutAlign="end" fxLayoutGap="10px" style="padding-right: 20px">
  <button mat-raised-button (click)="onConfirm(true)" color="primary">Sim</button>
  <button mat-raised-button (click)="onConfirm(false)">Não</button>
</div>