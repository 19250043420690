<div [formGroup]="formEnd">
<mat-form-field fxFlex="{{ width }}" appearance="outline">
    <mat-label>{{ label }}</mat-label>
        <input
            matInput
            formControlName="{{formControlName}}"
            placeholder="{{ placeholder }}"
            type="number"
            (input)="onInput($event)" 
        />

        <mat-error *ngIf="formEnd.get(fieldFormError)?.invalid">
        {{
            formValidator.getErrorMessage(formEnd,fieldFormError)
        }}</mat-error
        >
    </mat-form-field>
</div>