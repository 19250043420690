import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppMaterialModule } from './app-material/app-material.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { CrudContentComponent } from './components/crud-content/crud-content.component';
import { FormDynamicComponent } from './components/form-dynamic/form-dynamic.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { RowLayoutComponent } from './components/row-layout/row-layout.component';
import { TablePageComponent } from './components/table-page/table-page.component';
import { TipoPessoaPipe } from './pipes/tipo-pessoa.pipe';
import { ViewGeneralCommandEmpresaComponent } from './components/view-general-command-empresa/view-general.component';
import { ColumnLayoutComponent } from './components/column-layout/column-layout.component';
import { MatStepActionComponent } from './components/mat-step-action/mat-step-action.component';
import { GraficoComponent } from './components/grafico/grafico.component';
import { TextFieldCnpjComponent } from './components/text-field-cnpj/text-field-cnpj.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { TextFieldDateComponent } from './components/text-field-date/text-field-date.component';
import { TextFieldCepComponent } from './components/text-field-cep/text-field-cep.component';
import { TextFieldMoneyComponent } from './components/text-field-money/text-field-money.component';
import { TextFieldPhoneComponent } from './components/text-field-phone/text-field-phone.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { TextFieldNumberComponent } from './components/text-field-number/text-field-number.component';
import { GraficoBarrasComponent } from './components/graficoBarras/graficoBarras.component';
import { GraficoDonutsComponent } from './components/GraficoDonuts/GraficoDonuts.component';
import { GraficoEstadosComponent } from './components/graficoEstados/graficoEstados.component';
import { GraficoLinhasComponent } from './components/grafico-linhas/grafico-linhas.component';
import { CardSwitchComponent } from './components/card-switch/card-switch.component';
import { ToggleDashComponent } from './components/toggle-dash/toggle-dash.component';
import { VerbaCardComponent } from './components/verba-card/verba-card.component';
import { CardDuploComponent } from './components/card-duplo/card-duplo.component';
import { ViewGeneralCommandCampanhaComponent } from './components/view-general-command-campanha/view-general.component.campanha';
import { ViewGeneralCommandMercadoComponent } from './components/view-general-command-mercado/view-general.component.mercado';
import { ViewGeneralCommandFilialComponent } from './components/view-general-command-filial/view-general.component.filial';
import { CurrencyCnpj } from './components/currency-cnpj/currency-cnpj';
import { ConfirmationDialogComponentFormaPagamento } from './components/confirmation-dialog-forma-pagamento/confirmation-dialog-forma-pagamento';
import { BlockUIModule } from 'ng-block-ui';
import { RecuperarSenha } from '../core/pages/recuperar-senha/recuperar-senha.component';
import { GraficoOperadora } from './components/grafico-operadora/graficoOperadora.component';
import { GraficoView } from './components/grafico-view/graficoViewcomponent';
import { GraficoEstadosUsuComponent } from './components/graficoEstadosUsu/graficoEstadosUsu.component';
import { GraficoDonutsUsuComponent } from './components/GraficoDonutsUsu/GraficoDonutsUsu.component';
import { GraficoDesbloqueiosUsucomponent } from './components/grafico-desbloqueios-usu/graficoDesbloqueiosUsu.component';
import { GraficoDonutsDesbloqueioUsuComponent } from './components/GraficoDonutsDesbloqueiosUsu/GraficoDonutsDesbloqueiosUsu.component';
import { GraficoHoraComponent } from './components/graficoHora/graficoHora.component';
import { GraficoDiaSemanaComponent } from './components/graficoDiaSemana/graficoDiaSemana.component';
import { GraficoMesComponent } from './components/graficoMes/graficoMes.component';
import { GraficoDiaComponent } from './components/graficoDia/graficoDia.component';
import { MatCardModule } from '@angular/material/card';
import { GraficoEstadosUsuDesbloComponent } from './components/graficoEstadosUsuDesblo/graficoEstadosUsuDesblo.component';
import { GraficoCategoriaPreferenciaComponent } from './components/graficoCategoriaPreferencia/graficoCategoriaPreferencia.component';
import { NotificacaoComponent } from '../modules/campanha/components/notificacao/notificacao.component';
import { CrudContentBaseComponent } from './components/crud-content-base/crud-content-base.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';

@NgModule({
  declarations: [
    TipoPessoaPipe,
    FormDynamicComponent,
    TablePageComponent,
    ConfirmationDialogComponent,
    PageContentComponent,
    RowLayoutComponent,
    CrudContentComponent,
    ViewGeneralCommandEmpresaComponent,
    ColumnLayoutComponent,
    MatStepActionComponent,
    GraficoComponent,
    TextFieldCnpjComponent,
    TextFieldDateComponent,
    TextFieldCepComponent,
    TextFieldMoneyComponent,
    TextFieldPhoneComponent,
    TextFieldComponent,
    GraficoComponent,
    CardDuploComponent,
    TextFieldNumberComponent,
    VerbaCardComponent,
    GraficoBarrasComponent,
    GraficoDonutsComponent,
    GraficoEstadosComponent,
    GraficoLinhasComponent,
    GraficoOperadora,
    GraficoView,
    GraficoEstadosUsuComponent,
    GraficoDonutsUsuComponent,
    GraficoDesbloqueiosUsucomponent,
    GraficoDesbloqueiosUsucomponent,
    GraficoDonutsDesbloqueioUsuComponent,
    GraficoHoraComponent,
    GraficoDiaSemanaComponent,
    GraficoMesComponent,
    GraficoDiaComponent,
    GraficoEstadosUsuDesbloComponent,
    GraficoCategoriaPreferenciaComponent,
    CardSwitchComponent,
    ToggleDashComponent,
    ViewGeneralCommandCampanhaComponent,
    ViewGeneralCommandMercadoComponent,
    ViewGeneralCommandFilialComponent,
    CurrencyCnpj,
    ConfirmationDialogComponentFormaPagamento,
    RecuperarSenha,
    NotificacaoComponent,
    CrudContentBaseComponent,
    AlertDialogComponent,
  ],
  exports: [
    TipoPessoaPipe,
    FormDynamicComponent,
    TablePageComponent,
    ConfirmationDialogComponent,
    PageContentComponent,
    FlexLayoutModule,
    RowLayoutComponent,
    ColumnLayoutComponent,
    CrudContentComponent,
    ViewGeneralCommandEmpresaComponent,
    MatStepActionComponent,
    GraficoComponent,
    TextFieldCnpjComponent,
    TextFieldDateComponent,
    TextFieldCepComponent,
    TextFieldMoneyComponent,
    TextFieldPhoneComponent,
    TextFieldComponent,
    TextFieldNumberComponent,
    GraficoView,
    CardDuploComponent,
    VerbaCardComponent,
    GraficoBarrasComponent,
    GraficoDonutsComponent,
    GraficoEstadosComponent,
    GraficoLinhasComponent,
    GraficoOperadora,
    GraficoView,
    GraficoDesbloqueiosUsucomponent,
    GraficoDonutsUsuComponent,
    GraficoEstadosUsuComponent,
    GraficoDonutsDesbloqueioUsuComponent,
    GraficoHoraComponent,
    GraficoDiaSemanaComponent,
    GraficoMesComponent,
    GraficoDiaComponent,
    GraficoEstadosUsuDesbloComponent,
    GraficoCategoriaPreferenciaComponent,
    CardSwitchComponent,
    ToggleDashComponent,
    ViewGeneralCommandCampanhaComponent,
    ViewGeneralCommandMercadoComponent,
    ViewGeneralCommandFilialComponent,
    CurrencyCnpj,
    ConfirmationDialogComponentFormaPagamento,
    RecuperarSenha,
    MatCardModule,
    NotificacaoComponent,
    CrudContentBaseComponent,
    AlertDialogComponent,
  ],
  imports: [
    CommonModule,
    AppMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BlockUIModule.forRoot(),
  ],

  providers: [provideNgxMask()],
})
export class SharedModule {}
