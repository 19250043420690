import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-column-layout',
  templateUrl: './column-layout.component.html',
  styleUrls: ['./column-layout.component.css'],
})
export class ColumnLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
