<form *ngIf="(isExpanded || isShowing) && isPerfilAdm()">
  <mat-form-field class="example-chip-list">
    <mat-label>Empresa</mat-label>
    <mat-chip-grid #chipGrid aria-label="Empresa configuração">
      <mat-chip-row *ngFor="let empresa of empresasSelected" (removed)="remove(empresa)">
        {{ empresa.fantasia }}
        <button matChipRemove [attr.aria-label]="'remove ' + (empresa.fantasia)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </mat-chip-grid>
    <input
      placeholder="Selecione a empresa"
      #empresaInput
      [formControl]="formEmpresa"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)"
      *ngIf="empresasSelected.length === 0"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
    <mat-option *ngFor="let item of filteredEmpresas | async" [value]="item">
      {{ item.fantasia }}
    </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
