import { Component, Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { FormBuilder, FormGroup, NgControl } from '@angular/forms';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-text-field-money',
  templateUrl: './text-field-money.component.html',
  styleUrls: ['./text-field-money.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldMoneyComponent),
      multi: true,
    },
  ],
})
export class TextFieldMoneyComponent implements ControlValueAccessor {
  @Input() width: string = '100%';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() formControlName: string = '';
  @Input() fieldFormError: string = '';
  @Input() form!: FormGroup;
  @Input() formValidator!: FormValidatorService;

  constructor(private formBuilder: FormBuilder) {}

  onInput(event: any): void {
    const input = event.target;
    let value = input.value || ''; // Use uma string vazia se o valor for null ou undefined
    value = input.value.replace(/\D/g, '');
    let numberValue = parseFloat(value) / 100;
    
    if (value === '') {
      input.value = '';
      return;
    }
    if (this.label === 'Valor verba %' && numberValue > 100 || this.label === '% Usuário' && numberValue > 100) {
      numberValue = 100; 
    }

    this.form.controls[this.formControlName].setValue(numberValue);

    input.value = numberValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
    });

    console.log(numberValue)
  }

  // Implementação de ControlValueAccessor
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}

  
}

@Directive({
  selector: '[appCurrencyFormat]',
})
export class CurrencyFormatDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    // Remove pontos e substitui vírgulas por pontos
    const newValue = value.replace(/\./g, '').replace(',', '.');
    // Atualiza o valor no controle
    this.control.control?.setValue(newValue, { emitEvent: false });
  }

  @HostListener('blur')
  onBlur() {
    // Converte o valor para o formato americano (sem separador de milhares)
    const value = this.el.nativeElement.value;
    const newValue = parseFloat(
      value.replace(/\./g, '').replace(',', '.')
    ).toFixed(2);
    // Atualiza o valor no controle
    this.control.control?.setValue(newValue, { emitEvent: false });
  }
}
