import { Title } from 'chart.js';
import { TipoCampanha } from './modules/tipo-campanha/model/tipo-campanha';
import { VerbasModule } from './modules/verbas/verbas.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecuperarSenha } from './core/pages/recuperar-senha/recuperar-senha.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
    path: 'login',
    loadChildren: () =>
      import('./core/pages/login/login.module').then((m) => m.LoginModule),
  },

  {
    path: 'recuperar-senha',
    component: RecuperarSenha,
  },

  {
    path: 'empresa',
    loadChildren: () =>
      import('./modules/empresa/empresa.module').then((m) => m.EmpresaModule),
  },
  {
    path: 'meus_dados',
    loadChildren: () =>
      import('./modules/empresa/empresa.module').then((m) => m.EmpresaModule),
  },
  {
    path: 'segmento',
    loadChildren: () =>
      import('./modules/segmento/segmento.module').then(
        (m) => m.SegmentoModule
      ),
    title: 'Segmento',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    title: 'Dashboard',
  },
  {
    path: 'campanha',
    loadChildren: () =>
      import('./modules/campanha/campanha.module').then(
        (m) => m.CampanhaModule
      ),
    title: 'Campanha',
  },
  {
    path: 'filial',
    loadChildren: () =>
      import('./modules/filial/filiais.module').then((m) => m.FiliaisModule),
    title: 'Filial',
  },

  {
    path: 'fabricante',
    loadChildren: () =>
      import('./modules/fabricante/fabricante.module').then(
        (m) => m.FabricanteModule
      ),
    title: 'Fabricante',
  },

  {
    path: 'tipo-campanha',
    loadChildren: () =>
      import('./modules/tipo-campanha/tipo-campanha.module').then(
        (m) => m.TipoCampanhaModule
      ),
    title: 'Tipo campanha',
  },
  {
    path: 'Operadora',
    loadChildren: () =>
      import('./modules/operadora/operadora.module').then(
        (m) => m.OperadoraModule
      ),
    title: 'Operadora',
  },
  {
    path: 'segmentacao',
    loadChildren: () =>
      import('./modules/segmentacao/segmentacao.module').then(
        (m) => m.SegmentacaoModule
      ),
    title: 'Segmentação',
  },

  {
    path: 'verba',
    loadChildren: () =>
      import('./modules/verbas/verbas.module').then((m) => m.VerbasModule),
    title: 'Verba',
  },
  {
    path: 'cidade',
    loadChildren: () =>
      import('./modules/cidade/cidade.module').then((m) => m.CidadeModule),
    title: 'Cidade',
  },
  {
    path: 'estado',
    loadChildren: () =>
      import('./modules/estado/estado.module').then((m) => m.EstadoModule),
    title: 'Estado',
  },
  {
    path: 'tipo-camanha',
    loadChildren: () =>
      import('./modules/tipo-campanha/tipo-campanha.module').then(
        (m) => m.TipoCampanhaModule
      ),
    title: 'Tipo campanha',
  },
  {
    path: 'operadora',
    loadChildren: () =>
      import('./modules/operadora/operadora.module').then(
        (m) => m.OperadoraModule
      ),
    title: 'Operadora',
  },
  {
    path: 'fabricante',
    loadChildren: () =>
      import('./modules/fabricante/fabricante.module').then(
        (m) => m.FabricanteModule
      ),
    title: 'Fabricante',
  },
  {
    path: 'categoria-preferencia',
    loadChildren: () =>
      import(
        './modules/categoria-preferencia/categoria-preferencia.module'
      ).then((m) => m.CategoriaPreferenciaModule),
    title: 'Categoria Preferência',
  },

  {
    path: 'preferencia',
    loadChildren: () =>
      import('./modules/preferencia/preferencia.module').then(
        (m) => m.PreferenciaModule
      ),
    title: 'Preferência',
  },
  {
    path: 'sub-preferencia',
    loadChildren: () =>
      import('./modules/sub-preferencia/sub-preferencia.module').then(
        (m) => m.SubPreferenciaModule
      ),
    title: 'Sub-Preferência',
  },
  {
    path: 'nivel',
    loadChildren: () =>
      import('./modules/nivel/nivel.module').then((m) => m.NivelModule),
    title: 'Nível',
  },
  {
    path: 'editorial',
    loadChildren: () =>
      import('./modules/editorial/editorial.module').then(
        (m) => m.EditorialModule
      ),
    title: 'Editorial',
  },

  {
    path: 'performace',
    loadChildren: () =>
      import('./modules/performace/performace.module').then(
        (m) => m.PerformaceModule
      ),
    title: 'Performace',
  },
  {
    path: 'verba-empresa',
    loadChildren: () =>
      import('./modules/verba-empresa/verba-empresa.module').then(
        (m) => m.VerbaEmpresaModule
      ),
    title: 'Editorial',
  },
  {
    path: 'acao',
    loadChildren: () =>
      import('./modules/acao/acao.module').then((m) => m.AcaoModule),
    title: 'Acão Campanha',
  },
  {
    path: 'mercado',
    loadChildren: () =>
      import('./modules/mercado/mercado.module').then((m) => m.MercadoModule),
    title: 'Mercado',
  },

  {
    path: 'intervalo',
    loadChildren: () =>
      import('./modules/intervalo/intervalo.module').then(
        (i) => i.IntervaloModule
      ),
    title: 'Intervalo',
  },

  {
    path: 'forma',
    loadChildren: () =>
      import('./modules/forma-pagamento/forma-pagamento.module').then(
        (f) => f.FormaPagamentoModule
      ),
    title: 'Forma de Pagamento',
  },

  {
    path: 'consulta-empresas',
    loadChildren: () =>
      import('./modules/empresa-adm/empresa-adm.module').then(
        (f) => f.EmpresaAdmModule
      ),
    title: 'Empresa',
  },

  {
    path: 'remover-conta',
    loadChildren: () =>
      import('./modules/remover-conta/remover-conta.module').then(
        (f) => f.RemoverContaModule
      ),
    title: 'Remover Conta',
  },

  {
    path: 'conta-receber',
    loadChildren: () =>
      import('./modules/conta-receber/conta-receber.module').then(
        (f) => f.ContaReceberModule
      ),
    title: 'Conta Receber',
  },

  {
    path: 'equivalencia',
    loadChildren: () =>
      import('./modules/equivalencias/equivalencias-routing.module').then(
        (f) => f.EquivalenciasRoutingModule
      ),
    title: 'Equivalência',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
