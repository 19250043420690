import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { first, Observable } from 'rxjs';
import { IdentifiableMatriz } from 'src/app/core/model/identifiableMatriz';
import { Identifiable } from 'src/app/infra/model/identifiable';
import { Page } from 'src/app/shared/model/page';

import { environment } from '../../../environments/environment';

/**
 * Parâmetros extras para os métodos de serviço.
 */
export interface Extra {
  [key: string]: string;
}

export type IdentifiableOrMatriz<T = number> =
  | Identifiable<T>
  | IdentifiableMatriz;

export abstract class CrudService<T extends IdentifiableOrMatriz> {
  constructor(protected path: string, protected http: HttpClient) {}

  create(item: T, extra?: Extra): Observable<T> {
    const url = environment.backendUrl + this.buildPath(extra);

    return this.http.post<T>(url, item).pipe(first());
  }

  update(id: any, item: T, extra?: Extra): Observable<T> {
    const url = environment.backendUrl + this.buildPath(extra) + `/${id}`;

    return this.http.put<T>(url, item).pipe(first());
  }

  delete(id: any, extra?: Extra): Observable<any> {
    const url = environment.backendUrl + this.buildPath(extra) + `/${id}`;

    return this.http.delete(url).pipe(first());
  }

  retrive(id: any, extra?: Extra): Observable<T> {
    const url = environment.backendUrl + this.buildPath(extra) + `/${id}`;

    return this.http.get<T>(url).pipe(first());
  }

  /**
   * @deprecated
   *
   * Utilizar o método retrive
   */
  get(id: any, extra?: Extra): Observable<T> {
    return this.retrive(id, extra);
  }

  search(
    params: HttpParams,
    headers?: HttpHeaders,
    extra?: Extra
  ): Observable<Page<T>> {
    const url =
      environment.backendUrl + this.buildPath(extra) + this.endPointFilter();
    console.log(url);
    return this.http.get<Page<T>>(url, { params, headers }).pipe(first());
  }

  findAll(
    params: HttpParams,
    headers?: HttpHeaders,
    extra?: Extra
  ): Observable<T[]> {
    const url = environment.backendUrl + this.buildPath(extra);

    return this.http.get<T[]>(url, { params, headers }).pipe(first());
  }
  report(
    params: HttpParams,
    headers?: HttpHeaders,
    extra?: Extra
  ): Observable<HttpResponse<Blob>> {
    const url = environment.backendUrl + this.buildPath(extra) + '/report';

    return this.http.get(url, {
      headers,
      observe: 'response',
      params,
      responseType: 'blob',
    });
  }

  /**
   * Constroi o path a ser usado para as chamadas REST.
   * Por padrão apenas o próprio valor do path é retornado.
   *
   * @param extra informação extra para criação do path.
   */
  protected buildPath(extra?: Extra): string {
    if (this.isEndPointFilter()) {
      return this.path;
    } else {
      return this.path.substring(0, this.path.indexOf('$'));
    }
  }

  protected endPointFilter(): string {
    return this.isEndPointFilter() ? '/filter' : '';
  }

  protected isEndPointFilter(): boolean {
    return this.path.indexOf('$') == -1;
  }
}
