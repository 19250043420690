<div [formGroup]="form">
<mat-form-field fxFlex="{{ width }}" appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input
      matInput
      placeholder="{{ placeholder }}"
      formControlName="{{formControlName}}"
    />
    <mat-error *ngIf="form.get(fieldFormError)?.invalid">
      {{
        formValidator.getErrorMessage(form,fieldFormError)
      }}</mat-error
    >

  </mat-form-field>
</div>
