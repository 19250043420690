<mat-accordion class="example-headers-align" multi>
  <mat-expansion-panel flat [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>business</mat-icon>
      <span class="space">Dados</span>
    </mat-expansion-panel-header>
    <table>
      <tr>
        <td>Segmento:</td>
        <td>{{ getNomeSegmento(form.value.dados.idSegmento) }}</td>
      </tr>
      <tr>
        <td>CNPJ:</td>
        <td>{{ form.value.dados.cnpj }}</td>
      </tr>
      <tr>
        <td>Nome Filial:</td>
        <td>{{ form.value.dados.fantasia }}</td>
      </tr>
      <tr>
        <td>Inscrição Estadual:</td>
        <td>{{ form.value.dados.inscricaoEstadual }}</td>
      </tr>
      <tr>
        <td>Inscrição Municipal:</td>
        <td>{{ form.value.dados.inscricaoMunicipal }}</td>
      </tr>
    </table>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon>pin_drop</mat-icon>
      <span class="space">Endereço</span>
    </mat-expansion-panel-header>
    <table>
      <tr>
        <td>Logradouro:</td>
        <td>{{ form.value.endereco.end_tx_logradouro }}</td>
      </tr>
      <tr>
        <td>Número:</td>
        <td>{{ form.value.endereco.end_tx_numero }}</td>
      </tr>
      <tr>
        <td>Complemento:</td>
        <td>{{ form.value.endereco.end_tx_complemento }}</td>
      </tr>
      <tr>
        <td>Bairro:</td>
        <td>{{ form.value.endereco.end_tx_bairro }}</td>
      </tr>
      <tr>
        <td>Cidade:</td>
        <td>{{ getNomeCidade(form.value.endereco.cid_nr_id) }}</td>
      </tr>

      <tr>
        <td>Estado:</td>
        <td>{{ getNomeEstado(form.value.endereco.est_nr_id) }}</td>
      </tr>
    </table>
  </mat-expansion-panel>

  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-icon> contact_mail </mat-icon>
      <span class="space"> Contato </span>
    </mat-expansion-panel-header>
    <table>
      <tr>
        <td>Pessoa Contato:</td>
        <td>{{ form.value.contato.pessoaContato }}</td>
      </tr>
      <tr>
        <td>E-mail:</td>
        <td>
          {{ form.value.contato.email }}
        </td>
      </tr>
      <tr>
        <td>Telefone:</td>
        <td>{{ form.value.contato.telefone }}</td>
      </tr>
    </table>
  </mat-expansion-panel>
  <mat-expansion-panel [expanded]="true" *ngIf="form.value.autenticacao">
    <mat-expansion-panel-header>
      <mat-icon> lock_outline </mat-icon>
      <span class="space"> Autenticação </span>
    </mat-expansion-panel-header>
    <table>
      <tr>
        <td>Login:</td>
        <td>{{ form.value.autenticacao.login }}</td>
      </tr>
      <tr>
        <td>Senha:</td>
        <td>{{ getOcultarSenha() }}</td>
      </tr>
    </table>
  </mat-expansion-panel>
</mat-accordion>
