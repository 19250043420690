import { CommonModule } from '@angular/common';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { from } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrl: './text-field.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldComponent),
      multi: true
    }
  ]
})

export class TextFieldComponent implements ControlValueAccessor{
  @Input() width: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() formControlName: string = '';
  @Input() fieldFormError: string = '';
  @Input() form!: FormGroup;
  @Input() formValidator!: FormValidatorService;

  constructor(private formBuild: FormBuilder) {}

  // Implementação de ControlValueAccessor
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
}
