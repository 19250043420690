<div mat-dialog-content>
  <p>{{ message }}</p>
</div>
<div
  mat-dialog-actions
  fxLayoutAlign="end"
  fxLayoutGap="10px"
  style="padding-right: 20px"
>
  <button mat-raised-button (click)="onConfirm(true)" color="accent">
    Sim
  </button>
  <button mat-raised-button (click)="onConfirm(false)">Não</button>
</div>
