import { Empresa } from './../../../modules/empresa/model/empresa';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { UserContext } from '../model/user-context';
import { Constantes } from 'src/app/shared/constantes/constantes';
import { Router } from '@angular/router';

const chaveStorage = 'yeknekot';
const chaveStorageEmpresa = 'yik7yt4';
const chaveCloneStorageEmpresa = 'clone.yik7yt4';

/**
 * Serviço responsável por gerenciar UserContext.
 *
 * Para obter acesso ao usuário corrente, utilize o método `UserContextService.currentUser(): UserContext`.
 */
@Injectable({ providedIn: 'root' })
export class UserContextService {
  /**
   * Evento disparado para comunidar a atualização do UserContext.
   */
  readonly userContextUpdatedEvent: Observable<UserContext> = new Subject();

  // Mantém o objeto desserializado em memória para agilizar o acesso.
  private userContext: UserContext | null = null;
  private anonymousContext: UserContext = new UserContext();
  private storage = localStorage;
  private dataEmp = String;

  /**
   * Atualiza o UserContext corrente.
   * @param uc
   */
  set(uc: UserContext): void {
    uc.userInfo = this.parseJwt(uc.token);
    this.userContext = uc;
    this.storage.setItem(chaveStorage, JSON.stringify(uc));
    this.storage.removeItem(chaveStorageEmpresa);
    this.checkProfileAnunciante(uc.userInfo.perfis);
    this.fireUserContextUpdatedEvent();
  }

  /**
   * Remove o UserContext do storage.
   */
  clear(): void {
    this.userContext = null;
    this.storage.removeItem(chaveStorage);
    this.fireUserContextUpdatedEvent();
  }

  /**
   * Retorna o UserContext do usuário corrente. Se não tiver ocorrido login, retorna contexto anônimo.
   */
  currentUser(): UserContext {
    let currentUser: UserContext | null = null;

    if (this.userContext) {
      currentUser = this.userContext;
    } else {
      const data = this.storage.getItem(chaveStorage);
      if (data) {
        const uc: UserContext = UserContext.parseJson(data);
        if (uc) {
          this.userContext = UserContext.parseJson(data);
          currentUser = UserContext.parseJson(data);
        }
      }
    }

    if (currentUser) {
      if (this.isTokenValido(currentUser)) {
        return currentUser;
      } else {
        this.clear();
      }
    }

    return this.anonymousContext;
  }

  /**
   * Retorna `true` se a sessão tiver usuário logado.
   */
  isLoggedIn(): boolean {
    const logado: boolean = this.anonymousContext !== this.currentUser();

    return logado;
  }

  private fireUserContextUpdatedEvent(): void {
    const userContext = this.userContext ?? this.anonymousContext;
    (this.userContextUpdatedEvent as Subject<UserContext>).next(userContext);
  }

  /** Valida simplesmente o tempo de sessão. */
  private isTokenValido(uc: UserContext): boolean {
    if (typeof uc.token === 'undefined') {
      return false;
    }
    const jwt = this.parseJwt(uc.token);
    const agora = new Date();
    const validade = new Date(jwt.exp * 1000);
    return agora < validade;
  }

  private parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }

  public addkeyEmpresa(empresa: Empresa): void {
    this.storage.setItem(chaveStorageEmpresa, JSON.stringify(empresa));
  }
  public addNewEmpresaAndCloneEmpresaLogada(empresa: Empresa): void {
    const empresaLogada = this.admLogadoComoEmpresa();
    if (empresaLogada) {
      this.storage.setItem(
        chaveCloneStorageEmpresa,
        JSON.stringify(empresaLogada)
      );
    }
    this.addkeyEmpresa(empresa);
  }

  public admLogadoComoEmpresa(): any {
    const empresa: any = this.storage.getItem(chaveStorageEmpresa);
    if (this.valid(empresa)) {
      return JSON.parse(empresa);
    }
    return undefined;
  }

  public getCloneEmpresa(): any {
    const empresa: any = this.storage.getItem(chaveCloneStorageEmpresa);
    if (this.valid(empresa)) {
      return JSON.parse(empresa);
    }
    return undefined;
  }

  public valid(value: any): boolean {
    return value != null && value != undefined;
  }
  public removeKeyEmpresa(): void {
    this.storage.removeItem(chaveStorageEmpresa);
    const empresaClonada = this.getCloneEmpresa();
    if (this.valid(empresaClonada)) {
      this.addkeyEmpresa(empresaClonada);
      this.storage.removeItem(chaveCloneStorageEmpresa);
    }
  }

  public existKeyEmpresa(): boolean {
    return this.storage.getItem(chaveStorageEmpresa) !== null;
  }

  public getKeyEmpresa(): Empresa {
    const data: any = this.storage.getItem(chaveStorageEmpresa);
    const empresa: Empresa = JSON.parse(data);
    return Object.assign(new Empresa(), empresa);
  }

  public getProfiles(): string[] {
    return this.currentUser().userInfo.perfis;
  }

  public isExistsProfile(name: string): boolean {
    const profiles: string[] = this.getProfiles();
    if (profiles === null || profiles.length === 0) {
      return false;
    } else {
      return profiles.filter((profile) => profile === name).length > 0;
    }
  }

  public checkProfileAnunciante(profiles: String[]): void {
    if (profiles === null || profiles.length === 0) {
      return;
    }
    if (
      profiles.filter(
        (profile) =>
          profile.toUpperCase() === Constantes.PERFIL_ADM.toUpperCase()
      ).length > 0
    ) {
      return;
    }
    if (
      profiles.filter(
        (profile) =>
          profile.toUpperCase() === Constantes.PERFIL_ANUNCIANTE.toUpperCase()
      ).length > 0
    ) {
      const empresa: Empresa = new Empresa();
      empresa.id = this.currentUser().userInfo.id;
      this.addkeyEmpresa(empresa);
    }
    return;
  }

  public isProfileAdmin(): boolean {
    const perfis: String[] = this.currentUser().userInfo.perfis;
    return (
      perfis.filter(
        (x) => x.toUpperCase() == Constantes.PERFIL_ADM.toUpperCase()
      ).length > 0
    );
  }

  public getIdUserStorageEmpresa(): any {
    if (this.existKeyEmpresa()) {
      return this.getKeyEmpresa().id;
    } else {
      return null;
    }
  }
}
