import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { from } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-text-field-cep',
  templateUrl: './text-field-cep.component.html',
  styleUrl: './text-field-cep.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldCepComponent),
      multi: true
    }
  ]
})

export class TextFieldCepComponent implements ControlValueAccessor{
  @Input() width: string = '';
  @Input() label: string = 'CEP';
  @Input() placeholder: string = '00000-000';
  @Input() formControlName: string = 'end_nr_cep';
  @Input() fieldFormError: string = 'end_nr_cep';
  @Input() formEnd!: FormGroup;
  @Input() formValidator!: FormValidatorService;

  constructor(private formBuild: FormBuilder) {}
  // Implementação de ControlValueAccessor
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  
}
