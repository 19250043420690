import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonToggleModule} from '@angular/material/button-toggle';


@Component({
  selector: 'app-card-switch',
  templateUrl: './card-switch.component.html',
  styleUrl: './card-switch.component.scss'
})
export class CardSwitchComponent {

  @Input() titulo!: string;
  @Input() filtros: string[] = [];

  @Output() toggleChange = new EventEmitter<string>();

  recebeValorToggle(selected: any) {
    this.toggleChange.emit(selected.value);
  }

}
