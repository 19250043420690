<div class="notification-panel">
  <div class="notification-header">
    <h4>Notificações <span class="count">{{cartItems.length}}</span></h4>
  </div>
  <mat-divider class="divider"></mat-divider>

  <ul class="custom-list">
    <li *ngFor="let item of cartItems">
      <div class="item-details">
        <p class="titulo">{{ item.titulo }}</p>
      </div>
      <button (click)="statusItem(item)" mat-icon-button color="warn">
        <mat-icon style="font-size: 16px;">close</mat-icon>
      </button>
    </li>
  </ul>
</div>


