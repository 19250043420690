import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService, Extra } from 'src/app/infra/services/crud.service';
import { Menu } from '../model/menu';
import { Observable, first } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuUsuarioService extends CrudService<Menu> {
  constructor(httpClient: HttpClient) {
    super('menus', httpClient);
  }

  find(extra?: Extra, usuNrId?: number) {
    const url =
      environment.backendUrl + this.buildPath(extra) + `/usuario/${usuNrId}`;
    return this.http.get<Menu[]>(url).pipe(first());
  }
}
