import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { CommonModule, formatDate } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-text-field-date',
  templateUrl: './text-field-date.component.html',
  styleUrl: './text-field-date.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldDateComponent),
      multi: true
    }
  ]
})

export class TextFieldDateComponent implements ControlValueAccessor{
  @Input() width: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = 'dd/mm/yyyy';
  @Input() matDatepicker: string = '';
  @Input() formControlName: string = '';
  @Input() fieldformError: string = '';
  @Input() form!: FormGroup;
  @Input() formValidator!: FormValidatorService;

  constructor(private formBuild: FormBuilder) {}

  onInput(event: any): void {
    let value = event.target.value;

    value = value.replace(/\D/g, '');
    if (value.length <= 2) {
      value = value.replace(/(\d{0,2})/, '$1');
    } else if (value.length <= 4) {
      value = value.replace(/(\d{2})(\d{0,2})/, '$1/$2');
    } else {
      value = value
        .substring(0, 8)
        .replace(/(\d{2})(\d{2})(\d{0,4})/, '$2/$1/$3');
    }
    event.target.value = value;
  }

  // Implementação de ControlValueAccessor
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  
}
