import { F } from '@angular/cdk/keycodes';
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  ElementRef,
  Input,
} from '@angular/core';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { ChaveValor } from '../../model/chart/chave-valor';

@Component({
  selector: 'app-graficoBarras',
  templateUrl: './graficoBarras.component.html',
  styleUrls: ['./graficoBarras.component.css'],
})
export class GraficoBarrasComponent implements AfterViewInit {
  @Input() data: ChaveValor[] = [];
  @Input() label!: string;
  @Input() titulo!: string;

  @ViewChild('graficoBarra') graficoBarra!: ElementRef<HTMLCanvasElement>;

  constructor() {
    Chart.register(
      BarController,
      BarElement,
      CategoryScale,
      LinearScale,
      Tooltip
    );
    console.log('<<<<<<<<<<<<<constructor>>>>>>>>>>>>>');
  }

  ngAfterViewInit(): void {
    console.log('<<<<<<<<<<<<<values>>>>>>>>>>>>>');
    console.log(JSON.stringify(this.data));
    const values: number[] = this.data.map((d) => d.count);
    const labels: string[] = this.data.map((d) => d.label);

    const chart = new Chart(this.graficoBarra.nativeElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: this.label,
            data: values,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 0)',
            borderWidth: 0,
            barThickness: 8,
            borderRadius: 8,
            datalabels: {
              display: false,
            },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            mode: 'index',
            intersect: false,
            backgroundColor: 'rgba(0,0,0,0.8)',
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: '#666',
              maxRotation: 0,
              minRotation: 0,
              font: {
                size: 12,
                weight: 'bold',
              },
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              color: '#ddd',
            },
            ticks: {
              color: '#666',
            },
          },
        },
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
      },
    });
  }
}
