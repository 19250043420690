import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Chart, DoughnutController, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChaveValorDesbloqueioUsu } from '../../model/chart/chave-valor-desbloqueio-usu';

@Component({
  selector: 'app-grafico-donuts-desbloqueio-usu',
  templateUrl: './GraficoDonutsDesbloqueiosUsu.component.html',
  styleUrls: ['./GraficoDonutsDesbloqueiosUsu.component.css'],
})
export class GraficoDonutsDesbloqueioUsuComponent implements AfterViewInit {
  @Input() dados!: ChaveValorDesbloqueioUsu[];
  @Input() cores!: string[];
  @Input() titulo!: string;
  @Input() corFonteDatalabels: string = '#fff';
  @Input() tamanhoFonteDatalabels: number = 14;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;

  ngAfterViewInit() {
    if (this.dados && this.dados.length > 0) {
      this.renderizarGrafico();
    }
  }

  private renderizarGrafico(): void {
    Chart.register(DoughnutController, ArcElement, Tooltip, Legend, ChartDataLabels);

    const values: number[] = this.dados.map(d => d.quant);
    const labels: string[] = this.dados.map(d => d.label);

    new Chart(this.elemento.nativeElement, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: this.cores,
            borderRadius: 8,
            spacing: 5,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: true,
        layout: {
          padding: {
            bottom: 10,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
              boxWidth: 10,
              usePointStyle: true,
            },
          },
          datalabels: {
            color: this.corFonteDatalabels,
            font: {
              size: this.tamanhoFonteDatalabels,
            },
            formatter: (value, context) => {
              const data = context.chart.data.datasets[0].data.map(Number);
              const total = data.reduce((acc, current) => acc + current, 0);

              if (typeof total === 'number' && total > 0) {
                const percentage = Math.round((Number(value) / total) * 100);
                return percentage + '%';
              }
              return '';
            },
          },
        },
      },
    });
  }
}
