import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  Chart,
  DoughnutController,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Observable } from 'rxjs';
import { ChaveValor } from '../../model/chart/chave-valor';

@Component({
  selector: 'app-grafico-donuts',
  templateUrl: './GraficoDonuts.component.html',
  styleUrls: ['./GraficoDonuts.component.css'],
})
export class GraficoDonutsComponent implements OnInit {
  @Input() dados!: ChaveValor[];
  @Input() cores!: string[];
  @Input() titulo!: string;
  @Input() corFonteDatalabels: string = '#fff';
  @Input() tamanhoFonteDatalabels: number = 14;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;

  private chart: any;

  ngOnInit() {
    Chart.register(
      DoughnutController,
      ArcElement,
      Tooltip,
      Legend,
      ChartDataLabels
    );
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dados'] && !changes['dados'].isFirstChange()) {
      this.updateChart();
    }
  }

  createChart() {
    const values: number[] = this.dados.map((d) => d.count);
    const labels: string[] = this.dados.map((d) => d.label);

    this.chart = new Chart(this.elemento.nativeElement, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: this.cores,
            borderRadius: 8,
            spacing: 3,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: true,
        layout: {
          padding: {
            bottom: 10,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
              boxWidth: 10,
              usePointStyle: true,
            },
          },
          datalabels: {
            color: this.corFonteDatalabels,
            font: {
              size: this.tamanhoFonteDatalabels,
            },
            textAlign: 'center',
            backgroundColor: 'rgba(000, 000, 000, 0.4)',
            borderRadius: 4,
            formatter: (value, context) => {
              const data = context.chart.data.datasets[0].data.map(Number);
              const total = data.reduce((acc, current) => acc + current, 0);

              if (typeof total === 'number' && total > 0) {
                const percentage = Math.round((Number(value) / total) * 100);
                return percentage + '%';
              }
              return '';
            },
          },
        },
      },
    });
  }

  updateChart() {
    if (this.chart) {
      const values: number[] = this.dados.map((d) => d.count);
      this.chart.data.datasets[0].data = values;
      this.chart.update();
    }
  }
}
