import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { UserContext } from './infra/authz/model/user-context';
import { UserContextService } from './infra/authz/services/user-context.service';
import { notificacaoService } from './modules/campanha/components/notificacao/service/notificacao-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit, OnInit {
  @ViewChild('sidenav') sidenav!: MatSidenav;
  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;
  panelOpenState = false;
  fecharNotificacao = false;

  userContextService: UserContextService;
  user!: UserContext;
  description!: string;

  namecampanha!: string;

  isNotificaticacaoVisible = false;
  notificaticacaoCount!: number;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

  constructor(
    userContextService: UserContextService,
    private httpService: HttpClient,
    public title: Title,
    public router: Router,
    public notificacaoService: notificacaoService,
    private cdr: ChangeDetectorRef,

  ) {
    this.userContextService = userContextService;
  }
  ngOnInit(): void {
    this.notificacaoService.notificationCount$.subscribe(
      count => this.notificaticacaoCount = count
    );
  }

  ngAfterViewInit(): void {}

  isAuthenticate(): boolean {
    const logado = this.userContextService.isLoggedIn();
    return logado;
  }

  public habilitar(): void {
    this.router.navigate(['']);
    return;
  }

  getUsuarioLogado() {
    this.user = this.userContextService.currentUser();
    if (this.user) {
      return this.user.userInfo.sub;
    }
    return '';
  }

  showSubmenu1 = false;
  showSubmenu2 = false;

  toggleSubmenu(menuNumber: number): void {
    if (menuNumber === 1) {
      this.showSubmenu1 = !this.showSubmenu1;
      this.showSubmenu2 = false;
    } else if (menuNumber === 2) {
      this.showSubmenu2 = !this.showSubmenu2;
      this.showSubmenu1 = false;
    }
  }

  public logout() {
    this.userContextService.clear();
    this.router.navigate(['/login']);
  }

  toggleNotification() {
    this.isNotificaticacaoVisible = !this.isNotificaticacaoVisible;
  }

  fecharCampoNotificacoes(){
    this.isNotificaticacaoVisible = !this.isNotificaticacaoVisible;
  }
  
}
