import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-text-field-phone',
  templateUrl: './text-field-phone.component.html',
  styleUrl: './text-field-phone.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextFieldPhoneComponent),
      multi: true
    }
  ]
})
export class TextFieldPhoneComponent implements ControlValueAccessor{

  @Input() width: string = '';
  @Input() label: string = 'Telefone';
  @Input() placeholder: string ='(00) 0 0000-0000';
  @Input() formControlName: string ='';
  @Input() fieldformError: string = '';
  @Input() formContato!: FormGroup;
  @Input() formValidator!: FormValidatorService;

  constructor(private formBuild: FormBuilder) {}

  // Implementação de ControlValueAccessor
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {}
  
}
