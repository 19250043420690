import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { CategoriaPreferencia } from '../model/categoria-preferencia';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CategoriaPreferenciaService extends CrudService<CategoriaPreferencia> {
  constructor(public httpClient: HttpClient) {
    super('categoriasPreferecia', httpClient);
  }
}
