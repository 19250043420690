import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Menu } from '../../model/menu';
import { UserContextService } from 'src/app/infra/authz/services/user-context.service';
import { MenuUsuarioService } from '../../services/menu-usuario.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  @Input() isExpanded!: boolean;
  @Input() isShowing!: boolean;
  @Input() menuList!: Observable<Menu[]>;
  panelOpenState = false;
  constructor(
    public userContext: UserContextService,
    public menuService: MenuUsuarioService,
    public router: Router
  ) {}

  ngOnInit() {
    const user = this.userContext.currentUser().userInfo;

    this.menuList = this.menuService.find(undefined, user.id);
  }
  public setRouter(rota: string): void {
    console.log(rota);
    this.router.navigate([rota]);
  }
}
