<form [formGroup]="form" fxLayoutAlign="center center">
  <app-page-content class="card-emp" fxFlex="50%">
    <mat-card class="card-emp">
      <mat-card-header>
        <mat-card-title>Recuperar Senha</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content style="padding-top: 20px;">
        <div>
          <mat-stepper orientation="horizontal" [linear]="true" #stepper labelPosition="bottom">
            <mat-step #step1 [formGroup]="form" [completed]="form.valid" class="demo-stepper">
              <ng-template matStepLabel>Dados</ng-template>
              <app-row-layout>
                <app-text-field 
                  fxFlex="50%" 
                  label="Login" 
                  placeholder="Login" 
                  formControlName="usuTxLogin" 
                  fieldFormError="usuTxLogin"
                  [form]="form"
                  [formValidator]="formValidator"
                ></app-text-field>

                <app-text-field 
                  fxFlex="50%" 
                  label="E-mail" 
                  placeholder="Email" 
                  formControlName="usuTxEmail" 
                  fieldFormError="usuTxEmail"
                  [form]="form"
                  [formValidator]="formValidator"
                ></app-text-field>

              </app-row-layout>
              <app-mat-step-action>
                <button class="button" mat-button [disabled]="!form.valid" (click)="onDados()">
                  Confirmar
                </button>
              </app-mat-step-action>
            </mat-step>

            <mat-step #step1 [formGroup]="formCod" [completed]="formCod.valid" class="demo-stepper">
              <ng-template matStepLabel>Código</ng-template>
              <app-row-layout>
                <h3>Por favor, forneça o código enviado para o telefone associado</h3>
                <app-text-field 
                  fxFlex="100%" 
                  label="código" 
                  placeholder="Código" 
                  formControlName="usuTxCodigoSMS"   
                  fieldFormError="usuTxCodigoSMS"
                  [form]="formCod"
                  [formValidator]="formValidator"
                ></app-text-field>

              </app-row-layout>
              <app-mat-step-action>
                <button class="button" mat-button  [disabled]="!formCod.valid" (click)="onCodigo()">
                  Confirmar
                </button>
              </app-mat-step-action>
            </mat-step>

            <mat-step #step1 [formGroup]="formSenha" [completed]="formSenha.valid" class="demo-stepper">
              <ng-template matStepLabel>Alterar Senha</ng-template>
              <app-row-layout>
                <mat-form-field appearance="outline" class="mat-form-field" fxFlex="50%">
                  <mat-label>Nova senha</mat-label>
                  <input
                    matInput
                    [type]="hide ? 'password' : 'text'"
                    formControlName="usuTxSenha"
                  />
                  <mat-error *ngIf="formSenha.get('usuTxSenha')?.invalid">
                    {{ formValidator.getErrorMessage(formSenha, "usuTxSenha") }}</mat-error
                  >
                  <button
                    mat-icon-button
                    matSuffix
                    (click)="hide = !hide"
                    [attr.aria-label]="'Hide usuTxSenha'"
                    [attr.aria-pressed]="hide"
                  >
                    <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field appearance="outline" class="mat-form-field" fxFlex="50%">
                  <mat-label>Confirme a nova senha</mat-label>
                  <input
                    matInput
                    [type]="hide ? 'password' : 'text'"
                    formControlName="usuTxNewSenha"
                  />
                  <mat-error *ngIf="formSenha.get('usuTxNewSenha')?.invalid">
                    {{ formValidator.getErrorMessage(formSenha, "usuTxNewSenha") }}</mat-error
                  >
                  <button
                  mat-icon-button
                  matSuffix
                  (click)="hide = !hide"
                  [attr.aria-label]="'Hide usuTxNewSenha'"
                  [attr.aria-pressed]="hide"
                  >
                  <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                  </button>
                </mat-form-field>
              </app-row-layout>

              <app-mat-step-action>
                <button class="button" mat-button [disabled]="!formSenha.valid" (click)="onSenha()">
                  Confirmar
                </button>
              </app-mat-step-action>
            </mat-step>
            
          </mat-stepper>
        </div>
      </mat-card-content>

      <mat-card-actions
        fxLayoutAlign="end"
        fxLayoutGap="10px"
        style="padding-right: 20px;"
      >
        <button mat-raised-button (click)="navigateToSearch()" class="button-cancel">
          Cancelar
        </button>
    </mat-card-actions>

    </mat-card>
  </app-page-content>
</form>