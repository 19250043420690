import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MessageService } from 'src/app/core/services/message.service';

// Status de erro relacionado a CORS.
const STATUS_ERROR_CORS = 0;

@Injectable()
export class ConnectionErrorInterceptor implements HttpInterceptor {
  constructor(private msgService: MessageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (resp) => {},
        (httpError: HttpErrorResponse) => {
          if (httpError.status === STATUS_ERROR_CORS) {
            this.msgService.error('Não foi possível estabelecer conexão.');

            console.error('Status:', httpError.status);
            console.error('StatusText:', httpError.statusText);
            console.error('message:', httpError.message);
            console.error('URL:', httpError.url);
          }
        }
      )
    );
  }
}
