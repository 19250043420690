import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserContextService } from '../services/user-context.service';
import { ModalUtils } from '../../utils/modal-utils';
//import { BsModalService } from 'ngx-bootstrap/modal';

// Status de erro relacionado a UNAUTHORIZED.
const STATUS_ERROR_UNAUTHORIZED = 401;

@Injectable()
export class AuthenticationErrorInterceptor implements HttpInterceptor {
  constructor(
    protected router: Router,
    private userContextService: UserContextService
  ) //private modalService: BsModalService
  {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (resp) => {},
        (httpError: HttpErrorResponse) => {
          if (httpError.status === STATUS_ERROR_UNAUTHORIZED) {
            this.userContextService.clear();

            // fecha todos os modais
            //ModalUtils.closeAll(this.modalService);
            this.router.navigate(['login']);
          }
        }
      )
    );
  }
}
