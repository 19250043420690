import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-grafico',
  templateUrl: './grafico.component.html',
  styleUrl: './grafico.component.scss',
})
export class GraficoComponent {
  @Input() titulo!: string;
  @Input() valor!: any;
}
