import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { CategoriaPreferencia } from 'src/app/modules/categoria-preferencia/model/categoria-preferencia';
import { CategoriaPreferenciaService } from 'src/app/modules/categoria-preferencia/service/categoria-preferencia.service';
import { FormValidatorService } from '../../services/form-validator.service';
import { Operadora } from 'src/app/modules/operadora/model/operadora';
import { Fabricante } from 'src/app/modules/fabricante/model/fabricante';
import { SegmentacaoService } from 'src/app/modules/segmentacao/service/Segmentacao.service';
import { UserContextService } from 'src/app/infra/authz/services/user-context.service';
import { MessageService } from 'src/app/core/services/message.service';
import { FabricanteService } from 'src/app/modules/fabricante/service/fabricante.service';
import { OperadoraService } from 'src/app/modules/operadora/service/operadora.service';
import { Estado } from 'src/app/modules/estado/model/estado';
import { EstadoService } from 'src/app/modules/estado/service/estado.service';
import { TipoCampanhaService } from 'src/app/modules/tipo-campanha/service/tipo-campanha.service';
import { TipoCampanha } from 'src/app/modules/tipo-campanha/model/tipo-campanha';
import { fromByteArray } from 'base64-js';

@Component({
  selector: 'app-view-general-campanha',
  templateUrl: './view-general.component.campanha.html',
  styleUrls: ['./view-general.component.campanha.css'],
})
export class ViewGeneralCommandCampanhaComponent implements OnInit {
  @Input() form!: FormGroup<any>;
  @Input() title!: string;
  @Input() formValidator!: FormValidatorService;
  @Input() isCampanha: boolean = false;

  categoriasPreferencia!: Observable<CategoriaPreferencia[]>;
  fabricantes!: Observable<Fabricante[]>;
  operadoras!: Observable<Operadora[]>;
  estados!: Observable<Estado[]>;
  categoriasIds: number[] = [];
  categoriasNomes: string[] = [];

  operadorasList: Operadora[] = [];
  fabricanteList: Fabricante[] = [];
  categoriaPreferenciaList: CategoriaPreferencia[] = [];
  tipoCampanhaList: TipoCampanha[] = [];
  estadosList: Estado[] = [];

  dataBannerPrime: string | null = null;

  mapUrlFormControl: Map<string, string> = new Map([
    ['ET', 'publicidade.urlEtiqueta'],
    ['BP', 'publicidade.urlBannerPrime'],
    ['BC', 'publicidade.urlBannerCampanha'],
    ['GI', 'publicidade.urlGif'],
    ['HT', 'publicidade.urlHtml'],
  ]);

  constructor(
    public segmentacaoService: SegmentacaoService,
    public usuarioContexto: UserContextService,
    public messageService: MessageService,
    public categoriaPreferenciaService: CategoriaPreferenciaService,
    public fabricanteService: FabricanteService,
    public operadoraService: OperadoraService,
    public estadoService: EstadoService,
    public tipoCampanhaService: TipoCampanhaService
  ) {
    this.getCategoriasPreferencia();
    this.getFabricates();
    this.getOperadora();
    this.getEstado();
    this.getTipoCampanha();
  }

  ngOnInit(): void {
    this.dataBannerPrime = this.form.value.publicidade.dataBannerPrime;
  }

  getTipoCampanha(): void {
    this.tipoCampanhaService.findAll(new HttpParams()).subscribe((data) => {
      this.tipoCampanhaList = data;
    });
  }

  getCategoriasPreferencia(): void {
    this.categoriaPreferenciaService
      .findAll(new HttpParams())
      .subscribe((data) => {
        this.categoriaPreferenciaList = data;
      });
  }

  getEstado(): void {
    this.estadoService.findAll(new HttpParams()).subscribe((data) => {
      this.estadosList = data;
    });
  }

  getFabricates(): void {
    this.fabricanteService.findAll(new HttpParams()).subscribe((data) => {
      this.fabricanteList = data;
    });
  }

  getOperadora() {
    this.operadoraService.findAll(new HttpParams()).subscribe((data) => {
      this.operadorasList = data;
    });
  }

  onChangeSegmentacaoOperadora(ids: number[]): string[] {
    return this.getNomesPorIds(this.operadorasList, ids, 'operadora');
  }

  onChangeSegmentacaoFabricante(ids: number[]): string[] {
    return this.getNomesPorIds(this.fabricanteList, ids, 'fabricante');
  }

  onChangeSegmentacaoCategoriasPreferencias(ids: number[]): string[] {
    return this.getNomesPorIds(this.categoriaPreferenciaList, ids, 'nome');
  }

  onChangeEstado(ids: number[]): string[] {
    return this.getNomesPorIds(this.estadosList, ids, 'nome');
  }

  onChangeTipoCampanha(id: number): string {
    if (!this.tipoCampanhaList || !this.tipoCampanhaList.length) {
      console.warn('Lista de tipos de campanha ainda não carregada.');
      return '';
    }

    const tipoCampanhaEncontrada = this.tipoCampanhaList.find(
      (tc) => tc.id === id
    );
    return tipoCampanhaEncontrada
      ? tipoCampanhaEncontrada.nome
      : 'Desconhecida';
  }

  private getNomesPorIds<T extends { id: number }>(
    lista: T[],
    ids: number[],
    propriedade: keyof T
  ): string[] {
    if (!Array.isArray(ids)) {
      return ['Desconhecida'];
    }

    if (!lista || !lista.length) {
      return ['Desconhecida'];
    }

    const encontrados = lista
      .filter((item) => ids.includes(item.id))
      .map((item) => item[propriedade] as unknown as string);

    return encontrados.length > 0 ? encontrados : ['Desconhecida'];
  }

  getCase(value: any) {
    switch (value) {
      case 'PUBLICO_NACIONAL':
        return 'Público Nacional';
      case 'REGIOES':
        return 'Regiões';
      case 'GEO_LOCALIZACAO':
        return 'Geo Localização';
      default:
        return 'Desconhecido';
    }
  }

  onChangeGif(register: any): string {
    if (register && register.length > 0) {
      const base64String = fromByteArray(new Uint8Array(register));
      return 'data:image/gif;base64,' + base64String;
    }
    return this.form.value.publicidade.get(
      this.mapUrlFormControl.get('GI') + ''
    )?.value;
  }

  onChangeImage(register: any, tipo: string): string {
    if (register && register.length > 0) {
      let binary = '';
      const bytes = new Uint8Array(register);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      const base64String = window.btoa(binary);
      return 'data:image/jpeg;base64,' + base64String;
    }

    return this.form.get(this.mapUrlFormControl.get(tipo) + '')?.value;
  }
}
