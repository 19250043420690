<mat-sidenav-container class="example-container" autosize>
  <mat-sidenav
    #sidenav
    class="example-sidenav"
    mode="side"
    opened="true"
    (mouseenter)="mouseenter()"
    (mouseleave)="mouseleave()"
    style="background: white"
    *ngIf="isAuthenticate()"
    class="scroll element"
  >
    <!-- Conteúdo do sidenav -->
    <mat-toolbar style="background: white">
      <div fxLayout="row" fxLayoutGap="8px">
        <!-- Logo -->
        <div *ngIf="!isExpanded && !isShowing">
          <img src="../assets/img/logo/B1.png" class="icone" />
        </div>
        <div class="div-label">
          <span class="full-width" *ngIf="isExpanded || isShowing">
            <img src="../assets/img/logo/D4.png" class="icone-description" />
          </span>
        </div>
      </div>
    </mat-toolbar>
    <app-search-empresa
      [isExpanded]="isExpanded"
      [isShowing]="isShowing"
      *ngIf="isAuthenticate()"
    ></app-search-empresa>
    <app-menu
      [isExpanded]="isExpanded"
      [isShowing]="isShowing"
      *ngIf="isAuthenticate()"
    ></app-menu>
  </mat-sidenav>

  <!-- Conteúdo principal -->
  <mat-sidenav-content fxLayout="column" fxFlex>
    <header class="page-header" fxLayout="column">
      <mat-toolbar style="background: white" *ngIf="isAuthenticate()">
        <!-- Botão de menu -->
        <button mat-icon-button (click)="isExpanded = !isExpanded">
          <mat-icon color="primary">menu</mat-icon>
        </button>

        <a color="primary" mat-flat-button [routerLink]="['campanha/new']">
          <mat-icon>campaign</mat-icon>Nova Campanha
        </a>

        <span class="example-spacer"></span>

        <mat-chip-listbox>
          <mat-chip>
            <mat-icon color="primary" matChipAvatar>verified_user</mat-icon>
            {{ getUsuarioLogado() }}
          </mat-chip>
        </mat-chip-listbox>

        <button mat-icon-button color="primary" (click)="toggleNotification()">
          <mat-icon matBadge="{{ notificaticacaoCount }}" class="custom-badge"
            >notifications</mat-icon
          >
        </button>

        <app-notificacao *ngIf="isNotificaticacaoVisible"
          (closeNotificacao)="fecharCampoNotificacoes()"
        ></app-notificacao>

        <button
          mat-button
          color="primary"
          class="button-exit"
          (click)="logout()"
        >
          <mat-icon class="button-exit-icon">logout</mat-icon>
        </button>
      </mat-toolbar>

      <!-- Caminho da página -->
      <div class="router" *ngIf="isAuthenticate()">
        <span class="class-span">{{
          title.getTitle().substring(0, title.getTitle().indexOf("/"))
        }}</span>
        <span class="icon1"> &#10095;</span>
        <span class="icon"> &#10095;</span>
        <span class="class-span">{{
          title.getTitle().substring(title.getTitle().indexOf("/") + 1)
        }}</span>
      </div>
    </header>

    <!-- Conteúdo principal -->
    <main class="page-main" id="main" fxFlex>
      <router-outlet></router-outlet>
    </main>

    <!-- Rodapé -->
    <footer
      id="footer"
      class="page-footer"
      fxLayoutAlign="center center"
      *ngIf="isAuthenticate()"
    >
      <span>&#169; bubling</span>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
