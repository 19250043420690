import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  Chart,
  DoughnutController,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChaveValorUsu } from '../../model/chart/chave-valor-usu';

@Component({
  selector: 'app-grafico-donuts-usu',
  templateUrl: './GraficoDonutsUsu.component.html',
  styleUrls: ['./GraficoDonutsUsu.component.css'],
})
export class GraficoDonutsUsuComponent implements OnInit {
  @Input() dados!: ChaveValorUsu[];
  @Input() cores!: string[];
  @Input() titulo!: string;
  @Input() corFonteDatalabels: string = '#fff';
  @Input() tamanhoFonteDatalabels: number = 14;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;

  ngOnInit() {
    Chart.register(
      DoughnutController,
      ArcElement,
      Tooltip,
      Legend,
      ChartDataLabels
    );
    const faixasEtarias = {
      '13-18': { count: 0, color: '#23CF2A' },
      '18-30': { count: 0, color: '#00B3D2' }, 
      '31-45': { count: 0, color: '#f6cd00' }, 
      '45+': { count: 0, color: '#523f91' },
    };

    this.dados.forEach(dado => {
      const idade = parseInt(dado.label); 
      if (idade >= 13 && idade <= 18) {
        faixasEtarias['13-18'].count += dado.value;
      } else if (idade > 18 && idade <= 30) {
        faixasEtarias['18-30'].count += dado.value;
      } else if (idade > 30 && idade <= 45) {
        faixasEtarias['31-45'].count += dado.value;
      } else {
        faixasEtarias['45+'].count += dado.value;
      }
    });

    const labels = Object.keys(faixasEtarias) as (keyof typeof faixasEtarias)[];
    const values = labels.map(label => faixasEtarias[label].count);
    const cores = labels.map(label => faixasEtarias[label].color);

    new Chart(this.elemento.nativeElement, {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: cores,
            borderRadius: 8,
            spacing: 5,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: true,
        layout: {
          padding: {
            bottom: 10,
          },
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              padding: 20,
              boxWidth: 10,
              usePointStyle: true,
            },
          },
          datalabels: {
            color: this.corFonteDatalabels,
            font: {
              size: this.tamanhoFonteDatalabels,
            },
            formatter: (value, context) => {
              const data = context.chart.data.datasets[0].data.map(Number);
              const total = data.reduce((acc, current) => acc + current, 0);

              if (typeof total === 'number' && total > 0) {
                const percentage = Math.round((Number(value) / total) * 100);
                return percentage + '%';
              }
              return '';
            },
          },
        },
      },
    });
  }
}
