import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { TableColumn } from '../../model/table/table-column';
import { Page } from '../../model/page';

@Component({
  selector: 'app-table-page',
  templateUrl: './table-page.component.html',
  styleUrls: ['./table-page.component.scss'],
})
export class TablePageComponent implements OnInit, AfterViewInit {
  @ViewChild(MatAccordion) accordion!: MatAccordion;
  displayedColumns: string[] = [''];
  @Input() dataSource!: MatTableDataSource<any>;
  @Input() totalElements!: number;
  @Input() page!: Page<any>;
  @Input() columns!: TableColumn[];
  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() new = new EventEmitter();
  @Output() changePage = new EventEmitter();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  panelOpenState = false;

  constructor() {}

  ngOnInit() {
    this.displayedColumns = this.columns.map((column) => column.field);
    this.displayedColumns.push('actions');
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: any): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public onNew() {
    this.new.emit();
  }

  public onEdit(object: any): void {
    this.edit.emit(object);
  }

  public onDelete(object: any): void {
    this.delete.emit(object);
  }

  public onChangePage(event: any) {
    this.changePage.emit(event);
  }

  getColumnData(element: any, field: string): string {
    const fields = field.split('.');
    let value = element;

    fields.forEach((field) => {
      value = value[field];
    });

    return value;
  }

  isChecked(value: any): boolean {
    if (value === undefined) {
      return false;
    } else {
      return value === 1;
    }
  }

}
