import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { Estado } from '../model/estado';

@Injectable({
  providedIn: 'root',
})
export class EstadoService extends CrudService<Estado> {
  constructor(httpCliente: HttpClient) {
    super('estados', httpCliente);
  }
}
