<div class="op">
  <label for="option">Escolha uma opção:</label>
  <select id="option" (change)="onChangeOption($event)">
    <option value="month" selected>Mês</option>
    <option value="year">Ano</option>
  </select>

  <div *ngIf="showMonth">
    <label for="month">Mês:</label>
    <select id="month" name="month" (change)="onChangeMonth($event)" class="options">
      <option value="1" [selected]="selectedMonth === 1">Janeiro</option>
      <option value="2" [selected]="selectedMonth === 2">Fevereiro</option>
      <option value="3" [selected]="selectedMonth === 3">Março</option>
      <option value="4" [selected]="selectedMonth === 4">Abril</option>
      <option value="5" [selected]="selectedMonth === 5">Maio</option>
      <option value="6" [selected]="selectedMonth === 6">Junho</option>
      <option value="7" [selected]="selectedMonth === 7">Julho</option>
      <option value="8" [selected]="selectedMonth === 8">Agosto</option>
      <option value="9" [selected]="selectedMonth === 9">Setembro</option>
      <option value="10" [selected]="selectedMonth === 10">Outubro</option>
      <option value="11" [selected]="selectedMonth === 11">Novembro</option>
      <option value="12" [selected]="selectedMonth === 12">Dezembro</option>
    </select>
  </div>
  <label for="year">Ano:</label>
  <select id="year" name="year" [(ngModel)]="selectedYear" (change)="onChangeYear($event)" class="options">
    <option value="2024">2024</option>
    <option value="2023">2023</option>
    <option value="2022">2022</option>
  </select>
</div>

<div class="graf">
  <ng-container *ngIf="data.idade && data.idade.length > 0">
    <div fxflex="50%" style="display: flex">
      <app-grafico-donuts
        [dados]="data.idade"
        [cores]="['#5E4596', '#DDC234', '#23CF2A', '#E31515']"
        [titulo]="'Desbloqueios Por Idade'"
        [corFonteDatalabels]="'#ffffff'"
        [tamanhoFonteDatalabels]="14"
      ></app-grafico-donuts>
    </div>
  </ng-container>

  <ng-container *ngIf="data.estado && data.estado.length > 0">
    <div fxflex="50%" style="display: flex">
      <app-graficoEstadosUsuDesblo 
      [values]="data.estado"
      [titulo]="'Desbloqueios por estados'"
      ></app-graficoEstadosUsuDesblo>
    </div>
  </ng-container>
  <ng-container *ngIf="data.genero && data.genero.length > 0">
    <div fxflex="50%" style="display: flex" >
        <app-grafico-donuts
          [dados]="data.genero"
          [cores]="['#5E4596', '#00B3D2']"
          [titulo]="'Desbloqueios por gênero'"
          [corFonteDatalabels]="'#ffffff'"
          [tamanhoFonteDatalabels]="14"
        >
        </app-grafico-donuts>
    </div>
  </ng-container>
</div>
<p></p>
<div class="graf">
  <ng-container *ngIf="data.hora && data.hora.length > 0">
    <div fxflex="50%" style="display: flex">
      <app-grafico-hora
        [dados]="data.hora"
        [cores]="['#5E4596', '#00B3D2', '#DDC234', '#4BC0C0', '#9966FF', '#FF9F40', '#FFCD56', '#4CAF50']"
        [titulo]="'Desbloqueios Usuário por Hora'"
    ></app-grafico-hora>
    </div>
  </ng-container>
  
  <ng-container *ngIf="data.diaSemana && data.diaSemana.length > 0">
    <div fxflex="50%" style="display: flex; gap: 30px;">
      <app-grafico-dia-semana
        [dados]="data.diaSemana"
        [corLinha]="'#5E4596'"
        [titulo]="'Desbloqueio Usuário por Semana'"
    ></app-grafico-dia-semana>
    </div>
  </ng-container>
</div>
<p></p>
<div class="graf">
  <ng-container *ngIf="data.mes && data.mes.length > 0">
    <div fxflex="60%" style="display: flex">
      <app-grafico-mes
      [dados]="data.mes"
      [titulo]="'Desbloqueio Usuário por Mês'"
      [cores]="['#5E4596']" 
    ></app-grafico-mes>
    </div>
  </ng-container>
  
  <ng-container *ngIf="data.dia && data.dia.length > 0">
    <div fxflex="40%" style="display: flex; gap: 30px;">
      <app-grafico-dia
        class="space"
        [dados]="data.dia"
        [titulo]="'Desbloqueio Usuário Dia'"
    ></app-grafico-dia>
    </div>
  </ng-container>
</div>