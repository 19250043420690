<div fxLayout="row" fxLayoutAlign="{{ layoutAlign }}" style="height: 500px">
  <div fxFlex="{{ width }}" fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxLayout="column" fxFlex="100%">
      <mat-card-header color="primary">
        <mat-card-title>{{ title }}</mat-card-title>
      </mat-card-header>
      <mat-divider></mat-divider>
      <mat-card-content style="padding-top: 20px">
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  </div>
</div>
