<div class="card">
  <div class="cardInterno">
    <h2 class="titulo">{{ titulo }}</h2>
    <div class="valores">
      <h1 class="valor">
        {{ valorAtual | currency : "BRL" : "symbol" : "1.2-2" }}
      </h1>
      <h1 class="valor" id="valorTotal">/{{ formatarValor(valorTotal) }}</h1>
    </div>
    <progress
      id="progressbar"
      [value]="calcularPercentual()"
      [max]="100"
    ></progress>
  </div>
</div>
