import { Permission } from './permission';
import { UserInfo } from './user-info';
//import { RequiresPermission } from './requires-permission';

/**
 * Contém as informações de identficação de um usuário, suas permissões e grupos
 * associados.
 */
export class UserContext {
  token!: string;

  /** Identificação do usuário. */
  userInfo!: UserInfo;

  /** As permissões do usuário. */
  permissions: string[] = [];

  static parseJson(ucString: string): UserContext {
    /*
     * É necessário usar Object.assign para retornar o objeto com os métodos
     * declarados na classe.
     */
    const ucFromJson: UserContext = JSON.parse(ucString);
    const uc: UserContext = Object.assign(new UserContext(), ucFromJson);
    return uc;
  }

  /**
   * Verifica se o usuário tem a `permission` para o `object`.
   *
   * @param objectName Identificador do objeto.
   * @param permission Permissão a ser verificada.
   *
   * @return `true`, se o usuário tiver a permissão para o objeto.
   */
  hasPermission(objectName: string, permission: Permission): boolean {
    if (!objectName) {
      throw new Error('objectName é obrigatório');
    }
    if (!permission) {
      throw new Error('permission é obrigatório');
    }

    return (
      this.permissions &&
      this.permissions.indexOf(objectName + ':' + permission) > -1
    );
  }

  /**
   * Verifica se o usuário tem ao menos uma das `permissions` para o
   * `object`.
   *
   * @param objectName  Identificador do objeto.
   * @param permissions Uma ou mais permissões a serem verificadas.
   *
   * @return `true`, se o usuário tiver ao menos uma das permissões para o
   *         objeto.
   */
  hasAnyPermission(
    objectName: string,
    permissions: Permission | Permission[]
  ): boolean {
    if (!objectName) {
      throw new Error('objectName é obrigatório');
    }
    if (!permissions) {
      throw new Error('permissions é obrigatório');
    }

    const items = Array.isArray(permissions) ? permissions : [permissions];
    for (const p of items) {
      if (this.hasPermission(objectName, p)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Verifica se o usuário tem todas as `permissions` para o
   * `object`.
   *
   * @param objectName  Identificador do objeto.
   * @param permissions Uma ou mais permissões a serem verificadas.
   *
   * @return `true`, se o usuário tiver todas as permissões para o
   *         objeto.
   */
  hasAllPermissions(
    objectName: string,
    permissions: Permission | Permission[]
  ): boolean {
    if (!objectName) {
      throw new Error('objectName é obrigatório');
    }
    if (!permissions) {
      throw new Error('permissions é obrigatório');
    }

    const items = Array.isArray(permissions) ? permissions : [permissions];
    for (const p of items) {
      if (!this.hasPermission(objectName, p)) {
        return false;
      }
    }
    return true;
  }
}
