import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/infra/services/crud.service';
import { TipoCampanha } from '../model/tipo-campanha';
import { environment } from 'src/environments/environment';
import { first, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TipoCampanhaService extends CrudService<TipoCampanha> {
  constructor(httpClient: HttpClient) {
    super('tiposCampanha', httpClient);
  }

  findAllEmpresa(
    empNrId: number,
    params: HttpParams,
    headers?: HttpHeaders
  ): Observable<TipoCampanha[]> {
    const url = environment.backendUrl + 'tiposCampanha/empresa/' + empNrId;

    return this.http
      .get<TipoCampanha[]>(url, { params, headers })
      .pipe(first());
  }
}
