import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Ticks,
} from 'chart.js';
import { ChaveValor } from '../../model/chart/chave-valor';

@Component({
  selector: 'app-grafico-linhas',
  templateUrl: './grafico-linhas.component.html',
  styleUrls: ['./grafico-linhas.component.css'],
})
export class GraficoLinhasComponent implements AfterViewInit {
  @Input() data: ChaveValor[] = [];
  @Input() label!: string;
  @Input() titulo!: string;

  @ViewChild('graficoLinha') graficoBarra!: ElementRef<HTMLCanvasElement>;

  constructor() {
    Chart.register(
      LineController,
      LineElement,
      PointElement,
      CategoryScale,
      LinearScale,
      Tooltip
    );
  }

  ngAfterViewInit() {
    const labels: string[] = this.data.map((line) => line.label);
    const values: number[] = this.data.map((line) => line.count);
    const chart = new Chart(this.graficoBarra.nativeElement, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: this.label,
            data: values,
            backgroundColor: 'rgba(54, 162, 235, 0.5)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 2,
            datalabels: {
              display: false,
            },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },

        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: '#666',
              maxRotation: 0,
              minRotation: 0,
              font: {
                size: 12,
                weight: 'bold',
              },
            },
          },
        },
      },
    });
  }
}
