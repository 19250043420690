import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import {
  Chart,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { ChaveValor } from '../../model/chart/chave-valor';

@Component({
  selector: 'app-grafico-hora',
  templateUrl: './graficoHora.component.html',
  styleUrls: ['./graficoHora.component.css'],
})
export class GraficoHoraComponent implements OnInit {
  @Input() dados!: ChaveValor[];
  @Input() cores!: string[];
  @Input() titulo!: string;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;
  private chart!: Chart; 

  ngOnInit() {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dados']) {
      this.updateChart();
    }
  }

  private createChart() {
    Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend);
    const values: number[] = this.dados.map((d) => d.count);
    const labels: string[] = this.dados.map((d) => d.label);

    this.chart = new Chart(this.elemento.nativeElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: this.cores,
          },
        ],
      },
      options: {
        responsive: false,
        maintainAspectRatio: true,
        plugins: {
          datalabels: {
            color: 'white',
            font: {
              size: 12,
            },
          },
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              font: {
                family: 'Poppins', 
              },
            },
          },
          x: {
            ticks: {
              font: {
                family: 'Poppins', 
                weight: 'bold', 
              },
            },
          },
        },
      },
    });
  }

  updateChart() {
    if (this.chart) {
      const values: number[] = this.dados.map((d) => d.count);
      const labels: string[] = this.dados.map((d) => d.label);

      this.chart.data.labels = labels;
      this.chart.data.datasets[0].data = values;
      this.chart.update(); 
    }
  }
}