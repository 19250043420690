/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ResourceErrorInterceptor } from './resource-error-interceptor';
import { ConnectionErrorInterceptor } from './connection-erro-interceptor';
import { AuthorizationErrorInterceptor } from '../authz/http-interceptors/authorization-error.interceptor';
import { AuthenticationErrorInterceptor } from '../authz/http-interceptors/authentication-error.interceptor';
import { TokenRequestInterceptor } from '../authz/http-interceptors/token-request.interceptor';
import { UserContextInterceptor } from '../authz/http-interceptors/user-context.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: TokenRequestInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ResourceErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthorizationErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: UserContextInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationErrorInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ConnectionErrorInterceptor, multi: true }
];
