<div class="divCabecalho">
  <h2 class="titulo">{{ titulo }}</h2>
  <div class="toggleDiv">
    <mat-button-toggle-group (change)="change($event)">
      <mat-button-toggle *ngFor="let filtro of filtros" [value]="filtro">
        {{ filtro }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
