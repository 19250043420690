import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Cidade } from 'src/app/modules/cidade/model/cidade';
import { Estado } from 'src/app/modules/estado/model/estado';

@Component({
  selector: 'app-view-filial-general',
  templateUrl: './view-general.component.filial.html',
  styleUrls: ['./view-general.component.filial.css'],
})
export class ViewGeneralCommandFilialComponent implements OnInit {
  @Input() form!: FormGroup<any>;
  @Input() title!: string;
  @Input() estados: Estado[] = [];
  @Input() cidades: Cidade[] = []; 

  constructor() {}

  ngOnInit() {}

  getNomeEstado(id: number): string {
    const estado = this.estados.find((item) => item.id === id);
    return estado ? estado.nome : '';
  }

  getNomeCidade(id: number): string {
    const cidade = this.cidades.find((item) => item.id === id);
    return cidade ? cidade.nome : '';
  }

}
