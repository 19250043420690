import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-crud-content-base',
  templateUrl: './crud-content-base.component.html',
  styleUrls: ['./crud-content-base.component.scss'],
})
export class CrudContentBaseComponent {
  @Input() title: string | null = null;
  @Input() subTitle: string | null = null;
  @Input() width = '40%';
  @Input() layoutAlign = 'center top';
}
