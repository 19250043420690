<div class="card">
  <div class="cardInterno" id="primeiraInfo">
    <h2 class="titulo">{{titulo}}</h2>
    <h1 class="valor" id="primeiroValor">{{valor}}</h1>
  </div>
  <div class="divisor"></div>
  <div class="cardInterno" id="segundaInfo">
    <h2 class="titulo">{{titulo2}}</h2>
    <h1 class="valor" id="segundoValor">{{valor2}}</h1>
  </div>
</div>
