import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import {
  Chart,
  CategoryScale,
  LinearScale,
  Tooltip,
  LineController,
  LineElement,
  PointElement,
} from 'chart.js';
import { ChaveValor } from '../../model/chart/chave-valor';

@Component({
  selector: 'app-grafico-dia-semana',
  templateUrl: './graficoDiaSemana.component.html',
  styleUrls: ['./graficoDiaSemana.component.css'],
})
export class GraficoDiaSemanaComponent implements OnInit {
  @Input() dados!: ChaveValor[];
  @Input() corLinha!: string;
  @Input() titulo!: string;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;
  
  private chart: Chart | undefined;

  ngOnInit() {
    Chart.register(
      LineController,
      LineElement,
      PointElement,
      LinearScale,
      CategoryScale,
      Tooltip
    );

    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dados'] && !changes['dados'].isFirstChange()) {
      this.updateChart();
    }
  }

  private createChart() {
    const values: number[] = this.dados.map((d) => d.count);
    const labels: string[] = this.dados.map((d) => d.label);

    this.chart = new Chart(this.elemento.nativeElement, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            label: this.titulo,
            data: values,
            borderColor: this.corLinha,
            fill: false,
            tension: 0.1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            labels: {
              font: {
                family: 'Poppins', 
              },
            },
          },
          datalabels: {
            color: 'black',
            anchor: 'start',
            align: 'end',
            font: {
              family: 'Poppins',
            },
            formatter: function(value, context) {
              return value;
            }
          },
        },
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
        },
        scales: {
          x: {
            ticks: {
              font: {
                family: 'Poppins',
                weight: 'bold', 
              },
            },
          },
          y: {
            ticks: {
              font: {
                family: 'Poppins',
              },
            },
          },
        },
      },
    });
  }

  private updateChart() {
    if (this.chart) {
      const values: number[] = this.dados.map((d) => d.count);
      this.chart.data.datasets[0].data = values;
      this.chart.update();
    }
  }
  
}