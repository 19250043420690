import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { Observable, of, from } from 'rxjs';
import { tap } from 'rxjs/operators';

import { MessageService } from 'src/app/core/services/message.service';
import { ResourceError } from 'src/app/infra/model/resource-error';

// Status inicial de erro.
const STATUS_ERROR = 400;

// Status inicial de erro.
const STATUS_SERVER_ERROR = 500;

@Injectable()
export class ResourceErrorInterceptor implements HttpInterceptor {
  constructor(private msgService: MessageService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        (resp) => {},
        (httpError: HttpErrorResponse) => {
          if (httpError.status >= STATUS_ERROR) {
            if (httpError.error) {
              // Recupera o resourceError do objeto
              this.getResourceError(httpError.error).subscribe(
                (resourceError) => {
                  const msgErro = resourceError.userMessage
                    ? resourceError.userMessage
                    : resourceError.title
                    ? resourceError.title
                    : httpError.message;
                  this.msgService.error(msgErro);

                  if (resourceError.userMessage) {
                    if (httpError.status >= STATUS_SERVER_ERROR) {
                      console.error(
                        'Resource error: %s. \nDetalhe: %o.',
                        resourceError.detail,
                        resourceError
                      );
                    } else {
                      console.log(
                        'Resource error: %s. \nDetalhe: %o.',
                        resourceError.userMessage,
                        resourceError
                      );
                    }
                  }
                }
              );
            }
          }
        }
      )
    );
  }

  /**
   * Recupera o resourceErro a partir do erro recebido da resposta.
   *
   * Caso o erro seja um objeto blob, tentamos recuperar o resourceError
   * a partir deste objeto, caso contrário, assumimos que o erro será do tipo ResourceErro.
   *
   * @param error erro.
   * @returns Observable do resourceError.
   */
  getResourceError(error: any): Observable<ResourceError> {
    if (error instanceof Blob) {
      return from(
        (error as Blob).text().then((txt) => JSON.parse(txt) as ResourceError)
      );
    }

    return of(error as ResourceError);
  }
}
