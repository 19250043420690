import { Component, OnInit, ViewChild, ElementRef, Input, SimpleChanges } from '@angular/core';
import {
  Chart,
  CategoryScale,
  LinearScale,
  Tooltip,
  Title,
  BarController,
} from 'chart.js';
import { ChaveValor } from '../../model/chart/chave-valor';

@Component({
  selector: 'app-grafico-dia',
  templateUrl: './graficoDia.component.html',
  styleUrls: ['./graficoDia.component.css'],
})
export class GraficoDiaComponent implements OnInit {
  @Input() dados!: ChaveValor[];
  @Input() cores: string[] = ['#5E4596', '#5E4596', '#5E4596', '#5E4596'];
  @Input() titulo!: string;

  @ViewChild('canvaGrafico', { static: true }) elemento!: ElementRef;

  private chart: Chart | undefined;

  ngOnInit() {
    Chart.register(BarController, CategoryScale, LinearScale, Title, Tooltip);
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dados'] && !changes['dados'].isFirstChange()) {
      this.updateChart();
    }
  }

  private createChart() {
    const values: number[] = this.dados.map((d) => d.count);
    const labels: string[] = this.dados.map((d) => d.label);
  
    this.chart = new Chart(this.elemento.nativeElement, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: this.titulo,
          data: values,
          backgroundColor: this.cores,
        }],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              display: true,
            },
            ticks: {
              color: '#333',
              font: {
                family: 'Poppins', 
                weight: 'bold',    
              },
            },
          },
          y: {
            grid: {
              display: true,
            },
            ticks: {
              stepSize: 20,
              color: '#333',
              font: {
                family: 'Poppins', 
              },
            },
          },
        },
        plugins: {
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          legend: {
            labels: {
              font: {
                family: 'Poppins',
              },
            },
          },
          datalabels: {
            color: 'white',
            font: {
              size: 12,
            },
          },
        },
      },
    });
  }

  private updateChart() {
    if (this.chart) {
      const values: number[] = this.dados.map((d) => d.count);
      this.chart.data.datasets[0].data = values;
      this.chart.update();
    }
  }
}