import { DependencyMatriz } from './../../model/dependency-matriz';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserContextService } from '../services/user-context.service';
import { UserContext } from '../model/user-context';
import { Empresa } from 'src/app/modules/empresa/model/empresa';

/**
 * Se houver usuário logado, adiciona o token no request header `Authorization`.
 */
@Injectable()
export class TokenRequestInterceptor implements HttpInterceptor {
  constructor(private userContextService: UserContextService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.userContextService.isLoggedIn()) {
      const uc = this.userContextService.currentUser();
      if (request.url.indexOf('google') != -1) {
        return next.handle(request);
      }

      const authReq = request.clone({
        setHeaders: {
          Authorization: `Bearer ${uc.token}`,
        },
      });
      return next.handle(authReq);
    }
    return next.handle(request);
  }
}
