import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Cidade } from 'src/app/modules/cidade/model/cidade';
import { Estado } from 'src/app/modules/estado/model/estado';
import { Segmentacao } from 'src/app/modules/segmentacao/model/segmentacao';
import { Segmento } from 'src/app/modules/segmento/model/segmento';

@Component({
  selector: 'app-view-general',
  templateUrl: './view-general.component.html',
  styleUrls: ['./view-general.component.css'],
})
export class ViewGeneralCommandEmpresaComponent implements OnInit {
  @Input() form!: FormGroup<any>;
  @Input() title!: string;
  @Input() estados: Estado[] = [];
  @Input() cidades: Cidade[] = []; 
  @Input() segmento: Segmento[] = [];  

  constructor() {}

  ngOnInit() {}

  getNomeEstado(id: number): string {
    const estado = this.estados.find((item) => item.id === id);
    return estado ? estado.nome : '';
  }

  getNomeCidade(id: number): string {
    const cidade = this.cidades.find((item) => item.id === id);
    return cidade ? cidade.nome : '';
  }

  getNomeSegmento(id: number): string {
    const segmento = this.segmento.find((item) => item.id === id);
    return segmento?.nome ?? '';
  }

  getOcultarSenha(): string {
    return this.form.value.autenticacao.senha.replace(/./g, '*');
  }

}
