import { Legend } from 'chart.js';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ChaveValor } from '../../model/chart/chave-valor';

declare var google: any;

@Component({
  selector: 'app-graficoEstados',
  templateUrl: './graficoEstados.component.html',
  styleUrls: ['./graficoEstados.component.css'],
})
export class GraficoEstadosComponent implements AfterViewInit {
  @Input() values!: ChaveValor[];
  @Input() titulo!: string;

  @ViewChild('geochart') geochartElement!: ElementRef;

  private chart: any;

  ngAfterViewInit() {
    google.charts.load('current', {
      packages: ['geochart'],
    });
    google.charts.setOnLoadCallback(() => {
      this.drawRegionsMap();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['values'] && !changes['values'].isFirstChange()) {
      this.updateChart();
    }
  }

  drawRegionsMap() {
    const interacoes = this.values.map((v) => v.count);
    const data = google.visualization.arrayToDataTable([
      ['State', 'Interações'],
      ['BR-AC', interacoes[0]],
      ['BR-AL', interacoes[1]],
      ['BR-AM', interacoes[2]],
      ['BR-AP', interacoes[3]],
      ['BR-BA', interacoes[4]],
      ['BR-CE', interacoes[5]],
      ['BR-DF', interacoes[6]],
      ['BR-ES', interacoes[7]],
      ['BR-GO', interacoes[8]],
      ['BR-MA', interacoes[9]],
      ['BR-MG', interacoes[10]],
      ['BR-MS', interacoes[11]],
      ['BR-MT', interacoes[12]],
      ['BR-PA', interacoes[13]],
      ['BR-PB', interacoes[14]],
      ['BR-PE', interacoes[15]],
      ['BR-PI', interacoes[16]],
      ['BR-PR', interacoes[17]],
      ['BR-RJ', interacoes[18]],
      ['BR-RN', interacoes[19]],
      ['BR-RO', interacoes[20]],
      ['BR-RR', interacoes[21]],
      ['BR-RS', interacoes[22]],
      ['BR-SC', interacoes[23]],
      ['BR-SE', interacoes[24]],
      ['BR-SP', interacoes[25]],
      ['BR-TO', interacoes[26]],
    ]);

    const options = {
      region: 'BR',
      resolution: 'provinces',
      colorAxis: { colors: ['#23CF2A'] },
      backgroundColor: '#ffff',
      datalessRegionColor: '#00',
      defaultColor: '#f5f5f5',
    };

    this.chart = new google.visualization.GeoChart(
      this.geochartElement.nativeElement
    );
    this.chart.draw(data, options);
  }

  updateChart() {
    if (this.chart) {
      this.drawRegionsMap();
    }
  }
}
