import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-duplo',
  templateUrl: './card-duplo.component.html',
  styleUrls: ['./card-duplo.component.css'],
})
export class CardDuploComponent implements OnInit {
  @Input() titulo!: string;
  @Input() valor!: any;
  @Input() titulo2!: string;
  @Input() valor2!: any;

  constructor() {}

  ngOnInit() {}
}
