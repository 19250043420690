<form [formGroup]="form">
  <div
    *ngFor="let con of layoutComponent.containers; let container"
    class="{{ container.layout }}"
  >
    <div *ngFor="let el of container.elements; let element">
      <!-- COMPONENT INPUT-->
      <mat-form-field *ngIf="element.component === 'input-text'">
        <mat-label>{{ element.label }}</mat-label>
        <input
          matInput
          type="{{ element.type }}"
          placeholder="{{ element.placeholder }}"
          formControlName="{{ element.name }}"
        />
      </mat-form-field>

      <!-- COMPONENT RADIO GROUP-->

      <mat-radio-group
        *ngIf="element.component === 'radio-group'"
        aria-label="Select an option"
        formControlName="{{ element.name }}"
      >
        <mat-label>{{ element.label }}</mat-label>
        <mat-radio-button
          *ngFor="let opt of element.options"
          value="{{ opt.value }}"
          >{{ opt.display }}</mat-radio-button
        >
      </mat-radio-group>

      <!-- COMPONENT SELECT-->
      <mat-form-field
        *ngIf="element.component === 'select'"
        formControlName="id"
      >
        <mat-label>{{ element.label }}</mat-label>
        <mat-select>
          <mat-option *ngFor="let item of element.list; let data" [value]="item"
            >item</mat-option
          >
        </mat-select>
      </mat-form-field>

      <!-- COMPONENT DATE-->

      <mat-form-field *ngIf="element.component === 'date'">
        <mat-label>{{ element.label }}</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          formControlName="{{ element.name }}"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <!-- COMPONENT DATE-->
      <mat-form-field *ngIf="element.component === 'date-range'">
        <mat-label>>{{ element.label }}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="dataIncio"
            placeholder="Data inciial"
          />
          <input
            matEndDate
            formControlName="{{"
            element.name
            }}
            placeholder="Data final"
          />
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <hr />
  <div dir="rtl" fxLayoutGap="20px">
    <button mat-raised-button color="primary" (click)="onFilter()">
      Filtrar
    </button>
  </div>
</form>
