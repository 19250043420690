import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidatorService } from 'src/app/shared/services/form-validator.service';
import { UserDados } from './model/recuperar-senha';
import { RecuperarSenhaService } from './service/recuperar-senha.service';
import { MatStepper } from '@angular/material/stepper';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrl: './recuperar-senha.component.scss'
})

export class RecuperarSenha {
  
  @ViewChild('stepper') stepper!: MatStepper;

  hide = true;
  durationInSeconds = 5;

  form!: FormGroup;
  formSenha!: FormGroup;
  formCod!: FormGroup;

  constructor(
    public formBuilder: FormBuilder, 
    public formValidator: FormValidatorService,
    public recuperarSenhaService: RecuperarSenhaService,
    private snackBar: MatSnackBar,
    private router: Router,
  ){
    this.form = formBuilder.group({
      usuTxLogin:['', [Validators.required]],
      usuTxEmail:['', [Validators.required, Validators.email]],
    })

    this.formSenha = formBuilder.group({
      usuTxLogin:['', []],
      usuTxSenha:['', [Validators.required]],
      usuTxNewSenha:['', Validators.required],
      usuTxCodigoSMS:['', []],
      usuTxEmail:['', []]
    })

    this.formCod = formBuilder.group({
      usuTxCodigoSMS:['', [Validators.required]],
    })
  }

  public onDados() {
    if (this.form.valid) {
      const form: UserDados = this.form.value;
      console.log(JSON.stringify(form))
      this.recuperarSenhaService.postDadosUser(form).subscribe(
        () => {
          this.formSenha.patchValue({
            usuTxLogin: this.form.get('usuTxLogin')?.value,
            usuTxEmail: this.form.get('usuTxEmail')?.value,
          });

          this.stepper.next();
        },
        (error) => {
          this.showError(error);
        }
      );
    }
  }

  public onCodigo(){
    if (this.formCod.valid) {
      const formCod: UserDados = this.formCod.value;
      console.log(JSON.stringify(formCod))
      this.recuperarSenhaService.postCodigoUser(formCod).subscribe(
        () => {
          this.formSenha.patchValue({
            usuTxCodigoSMS: this.formCod.get('usuTxCodigoSMS')?.value,
          });
          this.stepper.next();
        },
        (error) => {
          console.log('Erro')
        }
      );
    }
  }

  public onSenha(){
    if (this.formSenha.valid) {
      const formSenha: UserDados = this.formSenha.value;
      console.log(JSON.stringify(formSenha))
      this.recuperarSenhaService.postSenhaUser(formSenha).subscribe(
        (sucess) => {
          this.sucess(sucess);
          this.router.navigate(['login'])
        },
        (error) => {
          console.error('Erro ao adicionar usuário:', error);
        }
      );
    }
  }

  public navigateToSearch(){
    this.router.navigate(['../'])
  }

  private showError(error: any) {
    this.snackBar.open(
      error.status === 400 ? 'Dados Incorretos!' : 'Falha',
      'X',
      {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      }
    );
  }

  private showErrorCode(error: any) {
    this.snackBar.open(
      error.status === 400 ? 'Código incorreto' : 'Falha',
      'X',
      {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      }
    );
  }
  
  private sucess(sucess: any) {
    this.snackBar.open(
      'Senha Alterada com Sucesso!',
      'X',
      {
        duration: this.durationInSeconds * 1000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      }
    );
  }

}
